import React, { useEffect, useState } from 'react';
import { csrfToken } from './csrfTokenUtils';
const useFetchUserData = (url = null) => {
    if(url == null) return {"loading":false, "error":'url is missing'};
    const [userData, setUserData] = useState({"loading":true});
    const fetchUserData = async () => {
        try {
            const response = await fetch(`/${url}`, {
                method: 'GET',
                cookie: {
                    'X-CSRF-Token': csrfToken(),
                },
            });
            const responseJSON = await response.json();
            setUserData({"loading":false, "data":responseJSON});
        } catch (error) {
            setUserData({"loading":false, "error":error});
        }
    }

    useEffect(() => {
        fetchUserData();
        return () => {
            setUserData({"loading":true});
        }
    },[])
    return userData;
}


export const useFetchUserInfo = () => {
    const [userProfile, setUserProfile] = useState({"loading":true});
    const fetchUserData = async () => {
        try {
            const data = await fetch(`/edit.json`, {method: 'GET',cookie: {'X-CSRF-Token': csrfToken(),},});
            const dataJson = await data.json();
            if(dataJson){
                const response = await fetch(`/profiles/${dataJson?.id}.json`, {method: 'GET',cookie: {'X-CSRF-Token': csrfToken(),},});
                const responseJSON = await response.json();
                setUserProfile({"loading":false, "data": {"userData":dataJson, "userProfileData":responseJSON, }});
            }else {
                setUserProfile({"loading":false, "error":response});
            }
        } catch (error) {
            setUserProfile({"loading":false, "error":error});
        }
    }

    useEffect(() => {
        fetchUserData();
        return () => {
            setUserProfile({"loading":true});
        }
    },[])
    return userProfile;
}

export const useFetchCompanyUserInfo = (userId) => {
    const [userProfile, setUserProfile] = useState({"loading":true});
    const fetchUserData = async () => {
        try {
            const response = await fetch(`${userId}.json`, {
                method: 'GET',
                cookie: {
                    'X-CSRF-Token': csrfToken(),
                },
            });
            const responseJSON = await response.json();
            setUserProfile({"loading":false, "data":responseJSON});
        } catch (error) {
            setUserProfile({"loading":false, "error":error});
        }
    }

    useEffect(() => {
        fetchUserData();
        return () => {
            setUserProfile({"loading":true});
        }
    },[])
    return userProfile;
}




export default useFetchUserData;