import React from "react"
import './EmbedSection.css'

const EmbedSection = ({ handleEmbedUrlUpload, siteUrl }) => {
    return (
        <>
            <div class="video-input-main">
                <div>
                    <input placeholder="Embed URL"
                        value={siteUrl}
                        onChange={(e) => handleEmbedUrlUpload(e, 'embed')}
                    />
                </div>
                <div class="video-top-para mt-2">
                    <p>
                        <em>
                            Copy and paste the full URL here (including https://)
                        </em>
                    </p>
                </div>
                <div>
                    <div class="wrapper mt-3">
                        <div class="button-outer-wrapper d-flex align-items-center">
                            <div class="button-inner-wrapper me-3">
                                <button id="js-button" class="js-button primary--button">

                                </button>
                            </div>
                            <div>
                                <p> Prevent scrolling</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmbedSection