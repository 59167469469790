import React from "react"
const ClickActionSection = () => {
    return (
        <div className="animation-select">
            <h5>
                ACTIONS (WHEN CLICKING ON ELEMENT )
            </h5>
            <div className="d-flex justify-content-between mt-2">
                <div className="element-actions__option mt-2 d-flex align-items-center">
                    <input type="radio" id="no-action" name="action" checked />
                    <label htmlFor="no-action">No action</label>
                </div>

                <div className="element-actions__option mt-2 d-flex align-items-center">
                    <input type="checkbox" id="reactivate-scrolling" name="action" />
                    <label htmlFor="reactivate-scrolling">Reactivate Scrolling</label>
                </div>
            </div>
            <div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="element-actions__option mt-3 ">
                    <div className="d-flex mb-3">
                        <input type="radio" id="hide-reveal" name="action" />
                        <label htmlFor="hide-reveal">Hide / Reveal<span title="Tooltip content">?</span></label>
                    </div>
                    <select className="animation-selector">
                        <option value="animation-type">Animation Type</option>
                        <option value="fade-in">Fade In</option>
                        <option value="fade-out">Fade Out</option>
                        <option value="slide-in">Slide In</option>
                        <option value="slide-out">Slide Out</option>
                        <div className="dropdown-divider"></div>
                        <option value="bounce-in">Bounce In</option>
                        <option value="bounce-out">Bounce Out</option>
                        <option value="zoom-in">Zoom In</option>
                        <option value="zoom-out">Zoom Out</option>
                    </select>
                </div>
                <div className="element-actions__option mt-3 ">
                    <div className="d-flex mb-3">
                        <label htmlFor="hide-reveal">Target Element</label>
                    </div>
                    <select className="animation-selector">
                        <option value="animation-type">Animation Type</option>
                        <option value="fade-in">Fade In</option>
                        <option value="fade-out">Fade Out</option>
                        <option value="slide-in">Slide In</option>
                        <option value="slide-out">Slide Out</option>
                        <div className="dropdown-divider"></div>
                        <option value="bounce-in">Bounce In</option>
                        <option value="bounce-out">Bounce Out</option>
                        <option value="zoom-in">Zoom In</option>
                        <option value="zoom-out">Zoom Out</option>
                    </select>
                </div>
                <div className="element-actions__option mt-3 ">
                    <div className="d-flex mb-3">
                        <label htmlFor="hide-reveal">Frequency <span title="Tooltip content">?</span></label>
                    </div>
                    <select className="animation-selector">
                        <option value="animation-type">Animation Type</option>
                        <option value="fade-in">Fade In</option>
                        <option value="fade-out">Fade Out</option>
                        <option value="slide-in">Slide In</option>
                        <option value="slide-out">Slide Out</option>
                        <div className="dropdown-divider"></div>
                        <option value="bounce-in">Bounce In</option>
                        <option value="bounce-out">Bounce Out</option>
                        <option value="zoom-in">Zoom In</option>
                        <option value="zoom-out">Zoom Out</option>
                    </select>
                </div>
            </div>
            <div className="element-actions__option d-flex align-items-center mt-3">
                <input type="radio" id="jump-to-page" name="action" />
                <label htmlFor="jump-to-page">Jump to page:</label>
                <input type="number" id="page-number" value="1" min="1" />
                <span> / 8</span>
            </div>
            <div className="element-actions__option d-flex align-items-center mt-3">
                <input type="radio" id="jump-to-page" name="action" />
                <label htmlFor="jump-to-page">Link to:</label>
                <input className="url-inp" placeholder="Enter URL,E-Mail or Phone" />
            </div>
            <div className="element-actions__option d-flex align-items-center mt-3">
                <input type="radio" id="jump-to-page" name="action" />
                <label htmlFor="jump-to-page">Reveal ClickPop:</label>
                <input className="url-inp" placeholder="Enter URL,E-Mail or Phone" />
            </div>
            <div className="element-actions__option d-flex align-items-center mt-3">
                <input type="radio" id="jump-to-page" name="action" />
                <label htmlFor="jump-to-page">Link to KDF:</label>
                <input className="url-inp" placeholder="Enter URL,E-Mail or Phone" />
            </div>
        </div>
    )
}
export default ClickActionSection