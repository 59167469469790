import React from "react";
export function clearAllCookies() {
    const cookies = document.cookie.split(";");
    cookies.forEach(cookie => {
        const [name] = cookie.split("=");
        document.cookie = `${name}=; expires=''; path=/`;
    });
}
export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
// console.log(document.cookie, getCookie('__profilin'))