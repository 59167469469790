import React from "react"
const TagSection = ({ tool, handleChange, totalPages }) => {
    return (
        <>
            <div className="tags-inp d-flex justify-content-between">
                <div>
                    <label htmlFor="">Tags:</label>
                    <input
                        placeholder="Please add a new Tag here"
                    />
                </div>
                <div>
                    <div className="toggle-container">
                        <label>High Priority Element: </label>
                        <input
                            type="checkbox"
                            name="priority"
                            id="toggle2"
                            value={tool?.priority}
                            onChange={handleChange}
                            className="toggle-input"
                        />
                        <label htmlFor="toggle2" className="toggle-btn"></label>
                    </div>
                </div>
            </div>
            <div className="color-select d-flex align-items-center justify-content-between">
                <div>
                    <p>
                        BACKGROUND COLOR:
                    </p>
                    <div className="colour-picker-field mt-2">
                        <input
                            className="colour-picker-field__item--picker"
                            name="bg_color"
                            type="color"
                            value={tool?.bg_color}
                            onChange={handleChange}
                        />
                        <input
                            className='colour-picker-field__item colour-picker-field__item--text'
                            value={tool?.bg_color}
                        />
                    </div>
                </div>
                <div className="show-on">
                    <p>
                        SHOW ON PAGE:
                    </p>
                    <div className="mt-2">
                        <input
                            type="number"
                            name="jump_to_page"
                            value={tool?.jump_to_page}
                        /> / {totalPages ?? 1}
                    </div>
                </div>
            </div>
        </>
    )
}
export default TagSection