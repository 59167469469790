import React from "react"
const LightBoxSetting = () => {
    return (
        <>
            <div class="image-popup">
                <div class="popup-content">
                    <div class="animation-select-top">
                        <input placeholder="Please Enter Lightbox Name" />
                    </div>
                    <div class="form-layout">
                        <div class="section-trigger">
                            <h3>TRIGGER</h3>
                            <label>
                                <input type="radio" name="trigger" checked />
                                When a related hotspot or image is clicked
                            </label>
                            <label>
                                <input type="radio" name="trigger" />
                                After <input type="text" class="input-small" /> sec on page: <input type="text"
                                    class="input-small" />
                            </label>
                            <label>
                                <input type="radio" name="trigger" />
                                On entry
                            </label>
                            <label>
                                <input type="radio" name="trigger" />
                                On exit intent
                            </label>
                            <label>
                                <input type="radio" name="trigger" />
                                On Hide/Reveal Action
                            </label>
                            <label>

                            </label>
                            <div class="toggle-container mt-5">
                                <label>High Priority Element: </label>
                                <input type="checkbox" id="toggle-set" class="toggle-input" />
                                <label for="toggle-set" class="toggle-btn"></label>
                            </div>
                        </div>

                        <div class="section-element">
                            <h3>INCLUDE ELEMENT</h3>
                            <label>
                                <input type="radio" name="element" />
                                Video
                            </label>
                            <label>
                                <input type="radio" name="element" />
                                Audio
                            </label>
                            <label>
                                <input type="radio" name="element" />
                                Text
                            </label>
                            <label>
                                <input type="radio" name="element" />
                                HTML
                            </label>
                            <label>
                                <input type="radio" name="element" />
                                Embed URL
                            </label>
                            <label className="align-item-center">
                                <input type="radio" name="element" />
                                Image
                                {/* <FontAwesomeIcon icon={faPlus} /> */}
                                <span className="image-add">+</span>

                            </label>
                            <label class="mt-4">
                                <input type="radio" name="element" />
                                Slide show
                            </label>
                        </div>
                    </div>
                    <div class="animation-select d-flex justify-content-around ">
                        <div class="d-flex select-setting-lower">
                            <label for="">Width:</label>
                            <input type="text" />
                        </div>
                        <div class="d-flex select-setting-lower">
                            <label for="">Height:</label>
                            <input type="text" />
                        </div>
                    </div>
                    <div class="lightbox-setting-lower-btn">
                        <button className="me-1">
                            Save & Close
                        </button>
                        <button>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LightBoxSetting