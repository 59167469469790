import React, { useState } from "react"
import DraggableComponent from "../../../CommonComponents/Draggable/DraggableComponent"
import '../../../../../assets/stylesheets/editorPage.css'
import EditorHeader from "./editorHeader/EditorHeader"
import { EditorInfoContext } from "../../../../utis/userInfoContext";
import AddElementModal from "../../../Modals/EditorModals/AddElementModal";
import ToolBoxHeading from "./editorToolbox/toolBox/ToolBoxHeading";
import EditorToolbox from "./editorToolbox/EditorToolbox"
import Viewer from "./pdfViewer/Viewer";
import PdfSidebar from "./editorSideBar/PdfSidebar";
// const styles = StyleSheet.create({
//     page: {
//       flexDirection: 'row',
//       backgroundColor: '#E4E4E4',
//     },
//     section: {
//       margin: 10,
//       padding: 10,
//       flexGrow: 1,
//     },
//   });
//   const MyDocument = () => (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <View style={styles.section}>
//           <Text>Section #1</Text>
//         </View>
//         <View style={styles.section}>
//           <Text>Section #2</Text>
//         </View>
//       </Page>
//     </Document>);

const EditKdfPage = (props) => {
    const [openSideSection, setOpenSideSection] = useState(false)
    const [zoomIndex, setZoomIndex] = useState({
        index: 3,
        value: '1'
    })

    // edit page context states
    const [editEnabled, setEditEnabled] = useState(false);
    const [openAddElement, setOpenAddElement] = useState(false);
    const [toolType, setToolType] = useState('')
    const [rectangles, setRectangles] = useState([]);
    const [activeRectangleIndex, setActiveRectangleIndex] = useState(null);
    const [geo, setGeo] = useState(
        {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            keep_proportions: true,
            keep_within_page: true,
            page: 1
        }
    )
    const [elements, setElements] = useState({})

    const { pdfFile, pdfImages } = props;

    return (
        <EditorInfoContext.Provider value={{
            editEnabled: editEnabled,
            openAddElement: openAddElement,
            rectangles: rectangles,
            activeRectangleIndex: activeRectangleIndex,
            geo: geo,
            toolType,
            elements: elements,
            setRectangles,
            setOpenAddElement,
            setEditEnabled,
            setActiveRectangleIndex,
            setGeo,
            setToolType,
            setElements,
        }}>

            {/* addElementModal */}
            {openAddElement &&
                <AddElementModal
                    openAddElementModal={openAddElement}
                    closeModal={setOpenAddElement}
                    docData={pdfFile}
                />
            }

            <div className="viewer-bg">
                {/* editor page header */}
                <EditorHeader
                    pdfFile={pdfFile}
                    setOpenSideSection={setOpenSideSection}
                    openSideSection={openSideSection}
                    setZoomIndex={setZoomIndex}
                    zoomIndex={zoomIndex}
                />
                <div className="editor-content">
                    {/* sidebar section  */}
                    {openSideSection && <PdfSidebar pdfImages={pdfImages} />}
                    {/* handle the pdf view component */}
                    {pdfFile &&
                        <Viewer
                            zoom={zoomIndex}
                            url={`${window.location.origin.toString()}${pdfFile?.file?.url}`}
                        />
                    }

                    {/* hanlded the edit toolbox */}
                    <DraggableComponent header={<ToolBoxHeading />}>
                        <EditorToolbox />
                    </DraggableComponent>
                </div>
            </div>
        </EditorInfoContext.Provider >
    )
}
export default EditKdfPage