import { faCircleInfo, faCircleMinus, faCirclePlus, faEllipsisVertical, faLessThan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const EditorHeader = ({ pdfFile, setOpenSideSection, openSideSection, setZoomIndex, zoomIndex }) => {
    const zoomingOptions = window.objInit?.zoomOptions

    const handleZoom = (option) => {
        if (option === 'in' && zoomIndex.index < 8) {
            setZoomIndex({
                ...zoomIndex,
                index: zoomIndex?.index + 1,
                value: zoomingOptions[Object.keys(zoomingOptions)[zoomIndex?.index + 1]].value
            })
        }
        if (option === 'out' && zoomIndex.index > 0 && zoomIndex.index < 9) {
            setZoomIndex({
                ...zoomIndex,
                index: zoomIndex?.index - 1,
                value: zoomingOptions[Object.keys(zoomingOptions)[zoomIndex?.index - 1]].value
            })
        }
    }

    return (
        <header className="header-editor">
            <div className="h-first">
                <div className="d-flex align-items-center">
                    <div className="">
                        <button
                            className="back-button"
                            onClick={() => window.location = "/kdfs"}
                        >
                            <FontAwesomeIcon
                                className="me-1"
                                icon={faLessThan}
                            />
                            KDFs
                        </button>
                    </div>
                    <div className="ms-2 three-dots"
                        onClick={() => setOpenSideSection(!openSideSection)}
                    >
                        <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            className="fs-4"
                        />
                    </div>
                    <div className="d-flex align-items-center ms-3 editor-inp me-3">
                        <input className="edit-value-inp ms-1 me-1" value={1} />
                        <p>/ {pdfFile?.num_pages}</p>
                    </div>
                </div>
            </div>

            <div className="title d-flex align-items-center justify-content-between">
                <div className="h-first-search align-items-center">
                    <i className="fa-solid fa-magnifying-glass ms-2"></i>
                    <i className="fa-solid fa-circle-check ms-2"></i>
                    <input className="h-first-inp ms-2 me-2" placeholder="Search..." />
                    <i className="fa-solid fa-caret-left"></i>
                    <i className="fa-solid fa-caret-right me-3"></i>
                </div>
                <p className="fs-4">
                    {pdfFile?.title}
                </p>
                <div>
                    <i className="fa-solid fa-share-from-square me-2"></i>
                </div>
            </div>
            <div className="icons-editor ms-2">
                <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 me-2 cursor-pointer"
                    onClick={() => handleZoom('in')}
                />
                <FontAwesomeIcon
                    icon={faCircleMinus}
                    onClick={() => handleZoom('out')}
                    className="fs-4 me-2 cursor-pointer"
                />
                <FontAwesomeIcon
                    className="fs-4 cursor-pointer"
                    icon={faCircleInfo}
                />
            </div>
        </header>
    )
}

export default EditorHeader