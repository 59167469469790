import Tooltip from "../../../CommonComponents/Tooltip/Tooltip";
import DeleteModal from "../../../Modals/ActionModals/DeleteModal"
import CreateLaserLinksModal from "../../../Modals/CreateLaserLinx/CreateLaserLinxModal"
import React, { useState } from 'react';
import dummyKdf from '../../../../../assets/images/dummykdf.jpg';
import noImage from '../../../../../assets/images/icon_upload.svg'
import Kinetizine from '../../../../../assets/images/Kinetizine.png'; 
import Button from "../../../Elements/Button";
import CustomTooltip from "../../../Elements/Tooltip";
import CommonModal from "../../../Modals/CommonModal";
import { Col, Container, Row } from "react-bootstrap";
import styles from './KdfListCard.module.css';
import CustomTags from "../../../Elements/CustomTags";
import utilsUploadFormData from "../../../../utis/upoadFormData";

const KdfListCard = ({data, triggerUdateRecord, triggerUpdateRecordData}) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [OpenLaserModal, setOpenLaserModal] = useState(false)
    const [deletePDFActiveState, setDeletePDFActiveState] = useState(null);
    const [privatePDFActiveState, setPrivatePDFActiveState] = useState(null);
    const [tagsEditStatus, setTagsEditStatus] = useState(false);

    const handlePDFStatusEventTrigger = async () => {
        let recordActionData;
        if(deletePDFActiveState != null){
            recordActionData = await customAPIRequest(`docs/${data.id}/files/${deletePDFActiveState}.json`, 'DELETE', {});
            if(recordActionData?.data != undefined){
                triggerUdateRecord();
            }
            setDeletePDFActiveState(null);
        }else if(privatePDFActiveState != null){
            // handleUpdateDocData({"doc_id": docs.id,"private_flag": docUploadData.docData.private_flag});
            triggerUdateRecord()
        }
    }


    return (
        <>
        {openDeleteModal && <DeleteModal openDeleteModal={openDeleteModal} closeModal={setOpenDeleteModal} />}
        {OpenLaserModal && <CreateLaserLinksModal OpenLaserModal={OpenLaserModal} closeModal={setOpenLaserModal} />}
        <CommonModal 
            title={deletePDFActiveState != null ? 'Delete File' : ''}
            modalStatus={deletePDFActiveState != null || privatePDFActiveState != null}
            changeActiveStatus={() => {setDeletePDFActiveState(null); setPrivatePDFActiveState(null)}}
            centered
            classList={'upload-kdf-modal-wrapper alert-modal'}
            footer={
                <div className="d-flex m-auto">
                    <Button text={'No'} classList={'alert delete-btn'} addonClass={'my-2 mx-2'} onClick={() => {setDeletePDFActiveState(null); setPrivatePDFActiveState(null)}} />
                    <Button text={'Yes'} classList={'success delete-btn'} addonClass={'my-2 mx-2'} onClick={handlePDFStatusEventTrigger} />
                </div>
            }
        >
            <p style={{fontSize:'14px'}}>
                {   
                    deletePDFActiveState != null ? 
                    'Are you sure you want to delete this file? This action cannot be undone.' 
                    :
                    // ( docUploadData?.docData.private_flag ? 'Make this KDF available to all users of this kinetizine account?' : 'Want to set this KDF to PRIVATE? This setting will hide this KDF from other kinetizine users within your organization but your recipients will still be able to view it via the KDF Link(s) you share with them.')
                    ''
                }
            </p>
        </CommonModal>
            <Row className={`${styles.listWrapper} ${!data.published_status ? styles.unPublished : ''}`}>
                <Col md={2}>
                    <img src={data.thumb != '' ? data.thumb : noImage} alt={data.title} className={`${styles.thumbImage} ${data.thumb != '' ? '' : styles.noThumbImage}`}  />
                    <Button 
                        text={'Edit KDF'} 
                        classList={`${data?.count_active_files !== 0 ? 'success' : 'disabled'} full-width`} 
                        addonClass={'my-2'} 
                        onClick={() => window.open(`/editor/${data?.id}`, '_blank')} 
                        disabled={data?.count_active_files == 0} 
                    />
                    <a target="_blank" href={`/kdfs/${data?.id}`}>KDF Settings</a>
                </Col>
                <Col md={8} className={styles.docWrapper}>
                    <Row>
                        <Col md={10}>
                            <p className={styles.docTitle}><strong>{data?.title}</strong></p>
                            <p className={styles.docText}>
                                <span>Created by: {data.current_user?.name || 'Name'} </span>
                                <span className={`ml-1 ${data.private_permission ? '' : 'blur_items'}`}>
                                    {data.num_pages === 1 ? `${data.num_pages} Page ` : `${data.num_pages ?? 0} Pages `}
                                    {
                                        data.count_live_files > 0 && !data.private_flag ?
                                        <CustomTooltip title={`${data.published_status ? 'Published' : 'Unpublished'} on ${data.doc_date_updated} by ${data.username}`}>
                                            <span>{data.published_status ? 'Published' : 'Unpublished'}</span>
                                        </CustomTooltip>
                                        :
                                        data.published_status ? 'Published' : 'Unpublished'
                                    }
                                </span>
                            </p>
                            {/* <p className={styles.docText}><span>Last Modified by: </span>{data.modifier ?? '-'}</p> */}
                            <p className={styles.docText} >
                                <span>Engagement Threshold: {data.page_viewed_sec} seconds</span>
                            </p>
                            <div className="custom-tag-wrapper" onClick={() => !tagsEditStatus && setTagsEditStatus(true)}>
                                <span className={styles.docText}>
                                    Tags: 
                                    {
                                        !tagsEditStatus ?
                                        (data?.tags?.length > 0 ?
                                        data?.tags?.map((tag, tagInd) => <span key={tagInd} className="tag-primary text-white mx-1 rounded">{tag.name}</span>)
                                        :
                                        <span className="custom-tag-element" onClick={() => setTagsEditStatus(true)}> Add KDF Tags here</span>)
                                        :
                                        <CustomTags 
                                            updateValue={(value) => triggerUpdateRecordData(value, data, 'tags')}
                                            tags={data?.tags}
                                            handleCancelEvent={(status) => setTagsEditStatus(!status)}
                                        />
                                    }
                                </span>
                            </div>
                            {data.stats.engagement > 0 && <div className="d-flex">
                                <span className={`${styles.statsLink} my-1 text-center`}><strong>{data.stats.viewers}</strong>Visits</span>
                                <span className={`${styles.statsLink} my-1 text-center`}><strong>{data.stats.min_viewed}</strong>Avg Duration</span>
                                <span className={`${styles.statsLink} my-1 text-center`}><strong>{data.stats.percent_viewed}</strong>Viewed</span>
                                <span className={`${styles.statsLink} my-1 text-center`}><strong>{data.stats.unique_viewers}</strong>Opt-in</span>
                                <span className={`${styles.statsLink} my-1 text-center`}><strong>{data.stats.engagement}</strong>Score</span>
                                <span className={`${styles.statsLink} my-1 text-center`}><strong>{data.stats.last_viewed}</strong>Last Viewed</span>
                            </div>}
                        </Col>
                        <Col md={2}>
                            {
                                data.doc_files_languages?.map(
                                (lanDoc, lanInd) => 
                                    <p>
                                        <img 
                                            key={lanInd} 
                                            src={`/assets/countries/${lanDoc?.language?.locale?.toLowerCase().split('-')[1] ?? 'en'}.png`} 
                                            alt={lanDoc?.language?.name} 
                                            width='22px' 
                                        />
                                    </p>
                                )
                            }
                        </Col>
                    </Row>
                </Col>
                <Col md={2} className="">
                    <div className="create-laser">
                    {/* onClick={() => { setOpenLaserModal(true) }} */}
                        <a href="#">Create Laser LINK</a>
                    </div>
                    <div className="laser-popups">
                        {/* onClick={() => { setOpenDeleteModal(true) }} */}
                        <div><a href="#">Delete this KDF</a></div>
                        <div>
                            <Tooltip text={'Clone KDF'}>
                                <a href="#">Clone this KDF</a>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip text={'Prevent other Kinetizine users from viewing and editing this KDF'}>
                                <a href="#">Make Private</a>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip text={'Generate Multiple LaserLINX using a spreadsheet'}>
                                <a href="#">Gen. MultiLINX</a>
                            </Tooltip>
                        </div>
                    </div>     
                </Col>
            </Row>
        </>
    )
}
export default KdfListCard;