import translations from "../common/Translations";

export const getTranslation = (path) => {
    const keys = path.split('.');
    let value = translations;
    keys.forEach(key => {
        value = value[key];
    });
    return value || '';
};

export function quality_data() {
    return [
        { name: "FASTEST LOADING", value: 'better2' },
        { name: "GOOD IMAGE QUALITY", value: 'good' },
        { name: "BEST IMAGE QUALITY", value: 'best1' },
        { name: "BYPASS IMAGE OPTIMIZATION", value: 'bypass' }
    ]
}


