import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({children, title, ...refs}) => {
    return(
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="custom-tooltip">
                    {title}
                </Tooltip>
            }
            {...refs}
            >
            {children}
        </OverlayTrigger>
    );
}
export default CustomTooltip;