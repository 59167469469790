import React, { useContext, useState } from "react";
import { csrfToken } from "../../../utis/csrfTokenUtils";
import { EditorInfoContext } from "../../../utis/userInfoContext";
import ElementModal from "../../Pages/Kdf/EditKdf/elementModal/ElementModal";
import ImageSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/imageSection/ImageSection";
import VideoSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/videoSection/VideoSection";
import TextEditorSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/textEditorSection/TextEditorSection";
import EmbedSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/embedSection/EmbedSection";
import HotspotSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/hotspotSection/HotspotSection";
import LightBoxSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/lightBoxSection/LightBoxSection";
import Button from "../../Elements/Button";
import PositionSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/PositionSection";
import TagSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/TagSection";
import AnimationSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/AnimationSection";
import ClickActionSection from "../../Pages/Kdf/EditKdf/elementModal/elementSections/elementFormSection/ClickActionSection";

const AddElementModal = ({ openAddElementModal, closeModal, docData }) => {
    const {
        setEditEnabled,
        geo,
        toolType,
        elements,
        setElements } = useContext(EditorInfoContext)

    const [tool, setTool] = useState({
        // properties going to be hadled dynamically
        authenticity_token: csrfToken(),
        doc_id: docData?.doc_id,
        docs_file_id: docData?.id,
        value: {},
        page: geo.page,
        geometry: {
            left: geo?.left,
            top: geo?.top,
            width: geo?.width,
            height: geo?.height,
            keep_proportions: true,
            keep_within_page: false
        },
        sec_to_delay: 0,
        disappear_after: 0,
        priority: false,
        bg_color: '',
        jump_to_page: '',
        timerField: 'ViewPort',
    })

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        switch (true) {
            case name === 'bg_color': {
                const r = parseInt(value.slice(1, 3), 16);
                const g = parseInt(value.slice(3, 5), 16);
                const b = parseInt(value.slice(5, 7), 16);
                setTool(prevVal => ({
                    ...prevVal,
                    [name]: `rgb(${r}, ${g}, ${b})`
                }));
                break;
            }
            case name == 'transparency': {
                setTool(prevVal => ({
                    ...prevVal,
                    [name]: Number(value) / 100
                }));
                break;
            }
            case name.startsWith('geometry.'): {
                const geometryKey = name.split('.')[1];
                setTool((prevValues) => ({
                    ...prevValues,
                    geometry: {
                        ...prevValues.geometry,
                        [geometryKey]: type === 'checkbox' ? checked : value,
                    },
                }));
                break;
            }
            default: {
                setTool(prev => ({
                    ...prev,
                    [name]: type === 'checkbox' ? checked : value
                }));
                break;
            }
        }
    }

    const handleImageUpload = (event) => {
        setEditEnabled(false)
        const file = event.target.files[0];
        setTool({
            ...tool,
            file_cache: '',
            vid_link: '',
            left: tool?.geometry.left,
            top: tool?.geometry?.top,
            proportion: "on",
            width: tool?.geometry?.width,
            height: tool?.geometry?.height,
            bg_color: '',
            transparency: tool?.transparency,
            action: "none",
            jump_to_page: '',
            href: '',
            kdf: '',
            element_type: '4',
            file: file,
            value: { timerField: "ViewPort" }
        })
    };

    const handleElementUpload = (event, type) => {
        let embedCode = event?.target?.value;
        let source = ""
        let videoLink = ''

        if (embedCode.includes('<iframe')) {
            let srcStartIndex = embedCode.indexOf('src="') + 5;
            let srcEndIndex = embedCode.indexOf('"', srcStartIndex);
            videoLink = embedCode.substring(srcStartIndex, srcEndIndex);
        } else {
            videoLink = event.target.value
        }
        if (-1 !== event?.target?.value?.indexOf('youtube')) {
            source = 'youtube'
        } else if (-1 !== event?.target?.value.indexOf('vimeo')) {
            source = 'vimeo'
        } else if (-1 !== event?.target?.value.indexOf('soundcloud')) {
            source = 'soundcloud'
        }
        setTool({
            ...tool,
            element_type: '5',
            other_files: [],
            tags: [],
            sec_to_delay: 0,
            disappear_after: 0,
            value: {
                timerField: "ViewPort",
                video_link: videoLink,
                ...(source && { source }),
                ...(type === 'embed' && { no_scroll: true, no_js: true })
            }
        })
    }

    const UploadImage = async () => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(tool)) {
            formData.append(key, typeof value === "object" ? JSON.stringify(value) : value);
        }
        try {
            const response = await fetch(`/docs/elements${toolType !== 'ImageToolbox' && '.json'}`, {
                method: 'POST',
                body: toolType === 'ImageToolbox' ? formData : JSON.stringify(tool),
                cookie: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            });
            if (response?.ok) {
                let jsonResponse = await response.json()
                handleCloseModal(false)
                setElements({ ...elements, [jsonResponse.id]: jsonResponse })
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    const handleCloseModal = (status) => {
        closeModal(status)
        setEditEnabled(status)
    }

    const ToolSection = () => {
        let result = {
            header: "",
            component: null,
        };

        switch (true) {
            case toolType === 'ImageToolbox':
                result.header = "Add Image";
                result.component = (
                    <ImageSection
                        media={tool?.file}
                        handleImageUpload={handleImageUpload}
                        UploadImage={UploadImage}
                    />
                );
                break;
            case toolType === 'VideoToolbox':
                result.header = "Add Video";
                result.component = (
                    <VideoSection
                        handleVideoUrlUpload={handleElementUpload}
                        videoLink={tool?.value?.video_link}
                    />
                );
                break;
            case toolType === 'TextToolbox':
                result.header = "Add Text";
                result.component = (
                    <TextEditorSection />
                );
                break;
            case toolType === 'EmbedToolbox':
                result.header = "Add Embed";
                result.component = (
                    <EmbedSection
                        handleEmbedUrlUpload={handleElementUpload}
                        siteUrl={tool?.value?.video_link}
                    />
                );
                break;
            case toolType === 'HotspotToolbox':
                result.header = "Add Hotspot";
                result.component = (
                    <HotspotSection
                        handleHotspotUpload={handleElementUpload}
                    />
                );
                break;
            case toolType === 'LightboxesToolbox':
                result.header = "Add Lightbox";
                result.component = (
                    <LightBoxSection />
                );
                break;
            default:
                result.header = "Add Image";
                result.component = (
                    <ImageSection
                        media={tool?.value?.video_link}
                        handleImageUpload={handleImageUpload}
                        UploadImage={UploadImage}
                    />
                );
        }
        return result;
    }

    return (
        <ElementModal
            openModal={openAddElementModal}
            heading={ToolSection()?.header}
            handleCloseModal={handleCloseModal}
        >
            <div className="image-popup">
                <div className="popup-content">
                    {ToolSection()?.component}
                    {toolType !== 'LightboxesToolbox' &&
                        <>
                            <PositionSection
                                handleChange={handleChange}
                                tool={tool}
                            />
                            <TagSection
                                handleChange={handleChange}
                                tool={tool}
                                totalPages={docData?.num_pages}
                            />
                            <AnimationSection
                                handleChange={handleChange}
                                tool={tool}
                            />
                            {
                                (toolType === 'ImageToolbox' || toolType === 'HotspotToolbox') && <ClickActionSection />
                            }
                            <div className="animation-select">
                                <p>
                                    Display this image on the following language editions of this KDF:
                                </p>
                            </div>
                            <div>
                                <Button
                                    classList={'success'}
                                    onClick={() => UploadImage()}
                                    text={'Save'}
                                />
                            </div>
                        </>}
                </div>
            </div>
        </ElementModal>
    )
}
export default AddElementModal;