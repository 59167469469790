import React from "react"
import Button from "../../../../../../Elements/Button"
const HotspotSection = () => {
    return (
        <div className="text-end">
            <Button
                classList='success'
                addonClass='me-2'
                text='Save'
            />
        </div>
    )
}
export default HotspotSection