import React, { useEffect, useState } from 'react';
import './GifSections.css'
import apiGETRequest from '../../../../../../../../utis/apiRequest';

const GifSection = ({ gifs = false }) => {
    const [data, setData] = useState({})

    useEffect(() => {
        const fetchGifsTenor = async () => {
            try {
                const gifList = await apiGETRequest(`editor/get-gifs/excited/${gifs && false}`);
                console.log(gifList, 'gifList');
                setData(gifList)
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };
        fetchGifsTenor();
    }, [])

    return (
        <div className='GIFs-main'>
            <div className="Gif-inp mb-2">
                <input placeholder="Search GIFs via GIPHY" />
            </div>
            <div className='gifs-items'>
                We come to the line with the problem, why this may not be ok or why we may need something else
                We come to the line with the problem, why this may not be ok or why we may need something else:We come to the line with the problem, why this may not be ok or why we may need something else:We come to the line with the problem, why this may not be ok or why we may need something else:We come to the line with the problem, why this may not be ok or why we may need something else:We come to the line with the problem, why this may not be ok or why we may need something else:We come to the line with the problem, why this may not be ok or why we may need something else:We come to the line with the problem, why this may not be ok or why we may need something else::
            </div>
        </div>
    );
};

export default GifSection;