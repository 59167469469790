import React, { useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import LightBoxSetting from "./LightBoxSetting"
import LightboxListing from "./LightBoxListing"

const LightBoxSection = () => {
    const [key, setKey] = useState('list');

    const handleSelect = (selectedKey) => {
        setKey(selectedKey);
    };

    return (
        <>
            {key === 'setting' && <div class="lightbox-setting-para">
                <p>
                    Configure the parameters of a lightbox below so that they trigger appropriately for your viewers.
                    Lightboxes can trigger when viewers click on an image or hotspot. They can also trigger after a given
                    period of time spent viewing the KDF or on a specific page of your doc.
                </p>
                <h6>
                    Please republish this KDF after modifying a lightbox

                </h6>
            </div>}
            <Tabs
                activeKey={key}
                onSelect={handleSelect}
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="list" title={'List'}>
                    <LightboxListing />
                </Tab>
                <Tab eventKey="setting" title={'Settings'}>
                    <LightBoxSetting />
                </Tab>
            </Tabs>
        </>
    )
}
export default LightBoxSection