import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import Giphy_logo from '../../../../../../../../assets/images/Giphy logo.png';
import tenor_logo from '../../../../../../../../assets/images/tenor_logo.svg';
import GifSection from "./gifSections/GifSection"
import Button from '../../../../../../Elements/Button'


const ImageSection = ({ media, handleImageUpload, UploadImage }) => {
    return (
        <>
            <Tabs
                defaultActiveKey="local"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="local" title={'Local Files'}>
                    {
                        !media ?
                            <div className="upload-file-btn"
                            >Select File
                                <input
                                    type="file"
                                    id="file-input"
                                    onChange={handleImageUpload} />
                            </div> :
                            <div className="file-data-main">
                                <div className="file-data">
                                    <img src={media && URL.createObjectURL(media)} />
                                </div>
                                <div className="file-info">
                                    <p>File Name : <span>{media?.name}</span></p>
                                    <p>Kind : <span>{media?.type?.substring(6)?.toUpperCase()} image</span></p>
                                    <p>Size : <span>{(media?.size / 1024)?.toFixed(2)} KB</span></p>
                                </div>
                                <div className="d-flex">
                                    <Button
                                        text={<><i className="fa-solid fa-upload" />Upload</>}
                                        classList={'success'}
                                        addonClass={'me-2'}
                                        onClick={() => UploadImage()}
                                    />
                                    <Button
                                        text={<><i className="fa-regular fa-circle-xmark"></i> Cancel</>}
                                        classList={'alert'}
                                        onClick={() => {
                                            setEditEnabled(false)
                                            closeModal(false)
                                        }}
                                    >
                                    </Button>
                                </div>
                            </div>
                    }
                    <p id="file-name-display" className="file-name-display"></p>
                </Tab>
                <Tab eventKey="gifs" title={<img src={Giphy_logo} alt='Giphy logo' height='20px' />}>
                    <GifSection gifs={true} />
                </Tab>
                <Tab eventKey="tenor" title={<img src={tenor_logo} alt='tenor logo' height='20px' />}>
                    <GifSection gifs={false} />
                </Tab>
            </Tabs>
        </>
    )
}
export default ImageSection