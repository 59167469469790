import React from "react"
const AnimationSection = ({ tool, handleChange }) => {
    return (
        <div className="animation-select">
            <div className="animation-card">
                <div className="animation-title">TIMED ANIMATIONS FOR ELEMENT</div>
            </div>
            <div className="animation-card">
                <div className="animation-title">APPEAR AFTER:</div>
                <input
                    type="number"
                    className="input-field"
                    value={tool?.sec_to_delay}
                    name='sec_to_delay'
                    onChange={handleChange}
                />
                <div className="color-picker-box"></div>
                <div className="animation-title">DISAPPEAR AFTER:</div>
                <input
                    type="number"
                    className="input-field"
                    name="disappear_after"
                    value={tool.disappear_after}
                    onChange={handleChange}
                />
            </div>
            <div className="animation-card">
                <select className="animation-selector">
                    <option value="animation-type">Animation Type</option>
                    <option value="fade-in">Fade In</option>
                    <option value="fade-out">Fade Out</option>
                    <option value="slide-in">Slide In</option>
                    <option value="slide-out">Slide Out</option>
                    <div className="dropdown-divider"></div>
                    <option value="bounce-in">Bounce In</option>
                    <option value="bounce-out">Bounce Out</option>
                    <option value="zoom-in">Zoom In</option>
                    <option value="zoom-out">Zoom Out</option>
                </select>
                <select className="animation-selector">
                    <option value="animation-type">Animation Type</option>
                    <option value="fade-in">Fade In</option>
                    <option value="fade-out">Fade Out</option>
                    <option value="slide-in">Slide In</option>
                    <option value="slide-out">Slide Out</option>
                    <div className="dropdown-divider"></div>
                    <option value="bounce-in">Bounce In</option>
                    <option value="bounce-out">Bounce Out</option>
                    <option value="zoom-in">Zoom In</option>
                    <option value="zoom-out">Zoom Out</option>
                </select>
                <select className="animation-selector">
                    <option value="animation-type">Animation Type</option>
                    <option value="fade-in">Fade In</option>
                    <option value="fade-out">Fade Out</option>
                    <option value="slide-in">Slide In</option>
                    <option value="slide-out">Slide Out</option>
                    <div className="dropdown-divider"></div>
                    <option value="bounce-in">Bounce In</option>
                    <option value="bounce-out">Bounce Out</option>
                    <option value="zoom-in">Zoom In</option>
                    <option value="zoom-out">Zoom Out</option>
                </select>
            </div>
            <div className="animation-card">
                <div className="d-flex align-items-center">
                    <input
                        type="radio"
                        className="toggle-button"
                        name="timerField"
                        value="ViewPort"
                    />
                    <label htmlFor="ViewPort">Whenever in viewport</label>
                </div>
                <div className="d-flex align-items-center">
                    <input
                        type="radio"
                        className="toggle-button"
                        name="timerField"
                        value="OncePerVisit"
                    />
                    <label htmlFor="OncePerVisit">Once Per visit</label>
                </div>
            </div>
        </div>
    )
}
export default AnimationSection