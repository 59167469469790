import { csrfToken } from "./csrfTokenUtils";

const apiGETRequest = async (url = null) => {
    if (url == null) return 'URL is missing';
    try {
        const response = await fetch(`/${url}`, {
            method: "GET",
            credentials: 'include', // Include cookies if necessary
            headers: {
                'X-CSRF-Token': csrfToken(),
            },
        });

        // if (!response.ok) {
        //     throw new Error(`HTTP error! Status: ${response.status}`);
        // }

        const responseJSON = await response.json();
        return responseJSON;
    } catch (error) {
        return {"error": error.message}; // Return the error message for clarity
    }
}
export const apiDeleteRequest = async (url = null) => {
    if(url == null) return 'url is missing';
    try {
        const response = await fetch(`/${url}`, {
            method: "DELETE",
            cookie: {
                'X-CSRF-Token': csrfToken(),
            },
        });
        const responseJSON = await response.json();        
        return responseJSON;
    } catch (error) {
        return error;
    }
}
export const apiPostRequest = async (url = null, data) => {
    if(url == null) return 'url is missing';
    try {
        const myHeaders = new Headers();
        myHeaders.append("Cookie", csrfToken());
        myHeaders.append("Content-Type", "application/json");

        const formData = new FormData();
        formData.append('authenticity_token', csrfToken());
        formData.append('_method', 'delete');

        const response = await fetch(`/${url}`, {
            method: "POST",
            headers:myHeaders,
            body: formData,
        });
        const responseJSON = await response.json();        
        return responseJSON;
    } catch (error) {
        return error;
    }
}
export default apiGETRequest;