import React from "react";
import Header from "./Header/Header";
import SideBar from "./Sidebar/SideBar";
import Footer from "./Footer/Footer";

const hocChild = React.memo((Component = null) => {
    return 'hh'
    return (props) => Component != null ? <Component {...props} /> : <>Nothing Found</>
})

const Layout = ({ header, children }) => {
    console.log('children ', children);
    
    const HOCChild = hocChild(children);
    return (
        <div>
            <div className="app-layout test">
                <div>
                    <SideBar />
                </div>
                <div className="right-wrapper">
                    <Header heading={header}/>
                    <main><HOCChild /></main>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;