import React from "react";
import MainBody from "./MainBody";

function AppContainer(props) {
  return (
    <MainBody {...props} />
  );
}

export default AppContainer;
