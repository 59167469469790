import { csrfToken } from "./csrfTokenUtils";

const customAPIRequest = async (url, formMethod, data) => {
    let result = false;
    try{
        const myHeaders = new Headers();
            myHeaders.append("Cookie", csrfToken());
            myHeaders.append("Content-Type", "application/json");
        let fetchData = await fetch(`/${url}`, {
            method:formMethod,
            headers:myHeaders,
            body:JSON.stringify({...data, 'authenticity_token':csrfToken()})
        });
        let fetchDataJSON = await fetchData.json();
        result = {'data': fetchDataJSON}
    }catch(error) {
        result = {'error': error.getMessage()}
    }
    return result;
}
export default customAPIRequest;