import React, { useState, useRef, useContext, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import TagEditorSection from '../TagEditorSection';
import { EditorInfoContext } from '../../../../../utis/userInfoContext';
import useCanvasHook from '../../CanvasSection/CanvasHook';
import DraggableElement from '../pdfEditorElements/DraggableElement';
import { apiDeleteRequest } from '../../../../../utis/apiRequest';

const PdfViewer = ({ pdfFile, zoom }) => {
    const {
        editEnabled,
        setEditEnabled,
        setOpenAddElement,
        rectangles,
        geo,
        elements,
        setRectangles,
        setActiveRectangleIndex,
        setGeo,
        setElements, } = useContext(EditorInfoContext)
    const { draw, normalizeDimensions } = useCanvasHook()

    const containerRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const canvasRefs = useRef([]);
    const [isDrawing, setIsDrawing] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setRectangles(new Array(numPages).fill([]));
    };

    const handleMouseDown = (event, index) => {
        const rect = canvasRefs.current[index].getBoundingClientRect();

        setIsDrawing(true);
        const startCoords = {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        };

        const newRectangles = [...rectangles];
        newRectangles[index] = [...newRectangles[index], { startCoords, currentCoords: startCoords, media: null }];

        setRectangles(newRectangles);
        setActiveRectangleIndex(newRectangles[index].length - 1);
    };

    const handleMouseMove = (event, index) => {
        if (!isDrawing) return;

        const rect = canvasRefs.current[index].getBoundingClientRect();
        const currentX = event.clientX - rect.left;
        const currentY = event.clientY - rect.top;

        const newRectangles = [...rectangles];
        const currentRectangles = newRectangles[index];
        const lastRectangle = currentRectangles[currentRectangles.length - 1];

        lastRectangle.currentCoords = { x: currentX, y: currentY };
        setRectangles(newRectangles);

        draw(index, canvasRefs);
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
        setOpenAddElement(true);
        rectangles?.filter((elem, index) => elem?.length > 0 && elem?.map((element, idx) => {
            setGeo({
                ...geo,
                left: element?.startCoords?.x,
                top: element?.startCoords?.y,
                width: element?.currentCoords?.x - element?.startCoords?.x,
                height: element?.currentCoords?.y - element?.startCoords?.y,
                page: index + 1
            })
        }))
    };

    const arrayOfElements = Object.values(Object.entries(elements).length !== 0 && elements);
    const handleDelete = async (elemId) => {
        try {
            const deleted = await apiDeleteRequest(`docs/elements/${elemId}.json`);
            setElements(prevElements => {
                const { [elemId]: _, ...newElements } = prevElements;
                return newElements;
            });
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    }

    useEffect(() => {
        setElements(window.objInit?.elements)
    }, [])

    return (
        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
                <div className='d-flex mb-2' key={index + 1}>
                    <TagEditorSection />
                    <div style={{ position: 'relative', cursor: `${editEnabled ? 'crosshair' : 'default'}` }} ref={containerRef}>
                        <Page
                            id={`'pageContainer'${+index + 1}`}
                            pageNumber={index + 1}
                            scale={parseFloat(zoom?.value)}
                            onLoadSuccess={({ width, height }) => {
                                let canvas = canvasRefs.current[index];
                                canvas.width = width;
                                canvas.height = height;
                            }}
                        />
                        <canvas
                            ref={el => (canvasRefs.current[index] = el)}
                            id={index + 1}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 2,
                                pointerEvents: 'auto',
                            }}
                            onMouseDown={(e) => {
                                editEnabled && (
                                    handleMouseDown(e, index),
                                    setActiveRectangleIndex({ pageIndex: index, rectIndex: rectangles[index].length })
                                )
                            }}
                            onMouseMove={(e) => editEnabled && handleMouseMove(e, index)}
                            onMouseUp={(e) => editEnabled && handleMouseUp()}
                        />

                        {arrayOfElements && arrayOfElements.map((element, idx) => {
                            let dimensions = {
                                top: normalizeDimensions(element.geometry.top, 1, zoom?.value),
                                left: normalizeDimensions(element.geometry.left, 1, zoom?.value),
                                height: normalizeDimensions(element.geometry.height, 1, zoom?.value),
                                width: normalizeDimensions(element.geometry.width, 1, zoom?.value)
                            }
                            return (
                                index + 1 === element?.page && (
                                    <div
                                        key={index + 2}
                                        style={{
                                            position: 'absolute',
                                            zIndex: 99,
                                            top: `${dimensions.top}px`,
                                            left: `${dimensions.left}px`,
                                            width: `${dimensions.width}px`,
                                            height: `${dimensions.height}px`,
                                        }}>
                                        {/* <iframe width="220" height="70" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                                        </iframe>
                                        <iframe src="https://kzine.com/" title="testing">
                                        </iframe> */}
                                        <DraggableElement
                                            element={element}
                                            key={idx}
                                            canvasRef={canvasRefs.current[index]}
                                            handleDelete={handleDelete}
                                            zoom={zoom}
                                            dimensions={dimensions}
                                        />
                                    </div>
                                )
                            )
                        })}
                    </div>
                </div>
            ))}
        </Document >
    );
};

export default PdfViewer;