import React from "react";

const LightboxListing = () => {
    return (
        <>
            <div class="content-box">
                <table class="info-table">
                    <thead>
                        <tr>
                            <th class="icon-add">+</th>
                            <th>Lightbox Name</th>
                            <th>Trigger</th>
                            <th>Reveal After</th>
                            <th>on Page</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </>
    )
}

export default LightboxListing;