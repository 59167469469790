import React, { useState } from "react";
import AddOrganisation from "../../Modals/AddOrganisationModal";
import SearchComponent from "../../CommonComponents/SearchComponent/SearchComponent";
import DataPerPage from "../../CommonComponents/Pagination/DataPerPage";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";

const AdminOrgPage = () => {
    const [openOrgModal, setOpenOrgModal] = useState(false);
    return (
        <>
            {openOrgModal && <AddOrganisation openOrgModal={openOrgModal} setOpenOrgModal={setOpenOrgModal} />}
            <Breadcrumb string={'Admin/Organizations'} />
            <div className="container">
                <div className="show-select1 d-flex ">
                    <DataPerPage />
                    <SearchComponent />
                </div>
                <div className="top-parent">
                    <table className="Subscription-table mt-4">
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                trial
                            </th>

                            <th className="hov-clr">
                                Last Login
                            </th>
                            <th className="hov-clr">
                                Date Time
                            </th>
                            <th className="hov-clr">
                                Users
                            </th>
                            <th className="hov-clr">
                                Banned Users
                            </th>
                            <th>
                                <button className="add-sub" onClick={() => { setOpenOrgModal(true) }}> <i className="fa-solid fa-building me-2"></i> Add Organization</button>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                test2.com <i className="fa-solid fa-circle-info"></i>
                            </td>
                            <td>
                                Agency B2B <i className="fa-solid fa-circle-info"></i>
                            </td>
                            <td>
                                3 Days
                            </td>
                            <td className="hov-clr">
                                nitin saini
                            </td>
                            <td className="hov-clr">
                                2024-07-12 @ 15:41:27 IST
                            </td>
                            <td className="hov-clr">
                                <i className="fa-solid fa-users me-2"></i>1 User
                            </td>
                            <td className="hov-clr">
                                <i className="fa-solid fa-user-slash me-2"></i>   0 Banned Users
                            </td>
                            <td className="hov-clr org-icon">
                                <a href="#"><i className="fa-solid fa-pen me-2"></i></a>
                                <a href="#"><i className="fa-solid fa-envelope me-2"></i></a>
                                <a href="#"><i className="fa-solid fa-coins me-2"></i></a>
                            </td>


                        </tr>


                    </table>
                </div>
                <div className="showing-main d-flex justify-content-between mt-3">
                    <div className="showing-para">
                        <p>Showing 1 to 2 of 2 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <span className="count-btn me-2">1</span>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminOrgPage;
