import React from "react";
import '../../../assets/stylesheets/modal.css'
import ModalLayout from "./ModalLayout";
const SupportModal = ({ setOpenSupport, openSupport }) => {
    const SupportModalBody = () => {
        return (
            <>
                <div className="row ">
                    <div className=" reason col-6 mb-2 dropdown">
                        <label className="mb-2">*Reason</label>
                        <input type="text" id="dropdown-input" placeholder="Please Select" />
                        <div className="dropdown-arrow"></div>
                        <div className="dropdown-content">
                            <div className="dropdown-item">Bug Report</div>
                            <div className="dropdown-item">Enhancement Request</div>
                            <div className="dropdown-item">Feature Request</div>
                            <div className="dropdown-item">Efficiency / Workflow Issue</div>
                            <div className="dropdown-item">Design / Ease of Use</div>
                            <div className="dropdown-item">Artwork</div>
                            <div className="dropdown-item">Content Issue</div>
                            <div className="dropdown-item">User Error</div>
                            <div className="dropdown-item">User Experience</div>
                            <div className="dropdown-item">Other</div>
                        </div>
                    </div>
                    <div className="col-sm-12 mb-2">
                        <div className="text-ared-inp">
                            <label className="mb-2">* What I did</label>
                            <textarea></textarea>
                        </div>
                    </div>

                    <div className="col-sm-12 mb-2 ">
                        <div className="text-ared-inp">
                            <label className="mb-2">* What I expected</label>
                            <textarea></textarea>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="text-ared-inp">
                            <label className="mb-2">* What happened</label>
                            <textarea></textarea>
                        </div>
                    </div>

                </div>
                <div className=" mb-2 notes">
                    <p className="mb-0">* Note: Please include links to screen shots or video using <a href="#">Monosnap</a> or <a
                        href="">Loom</a>.</p>
                </div>
                <div className="send-btn">
                    <button>Send</button>
                </div>
            </>
        )
    }
    return (

        <ModalLayout
            openModal={openSupport}
            title={'Send Feedback'}
            children={<SupportModalBody />}
            closeModal={setOpenSupport}
            modalWidth={'600px'}
        />
    )
}

export default SupportModal;