import React from "react"
import Draggable from "react-draggable";
import ResizeableElement from './ResizeableElements'
import ElementIcons from "./ElementIcons";


const DraggableElement = ({ idx, canvasRef, element, handleDelete, zoom, dimensions }) => {
    const handleDrag = (e, data) => {
        if (!canvasRef) return;
        const canvasRect = canvasRef.getBoundingClientRect();
        // console.log(canvasRect, 'canvasRect', '\n', 'data', data);
        let newX = data.x;
        let newY = data.y;

        if (newX < 0) newX = 0;
        if (newX + element.geometry.width > canvasRect.width) {
            newX = canvasRect.width - element.geometry.width;
        }
        if (newY < 0) newY = 0;
        if (newY + element.geometry.height > canvasRect.height) {
            newY = canvasRect.height - element.geometry.height;
        }
        // console.log(newX, newY, 'coordiantes');


        return { x: newX, y: newY };
    };

    return (
        <Draggable
            draggable={false}
            key={idx}
            handle=".handle"
            position={null}
            onDrag={handleDrag}
        >
            <div className='handle'>
                <div>
                    {element && element?.element_type_name === "Image" && (
                        <ResizeableElement
                            width={dimensions?.width}
                            height={dimensions?.height}
                        >
                            <ElementIcons
                                handleDelete={handleDelete}
                                element={element}
                                dimensions={dimensions}
                            />
                            <div style={{ position: 'relative' }}>
                                <img
                                    src={element.file_url}
                                    alt="Uploaded image"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        border: "2px solid red",
                                        transform: `scale(${parseFloat(zoom?.value)})`,
                                        position: 'relative' 
                                    }}
                                />
                            </div>
                        </ResizeableElement>
                    )}
                </div>
            </div>
        </Draggable>
    )
}
export default DraggableElement