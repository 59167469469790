import React, { useContext } from "react";
import { EditorInfoContext } from "../../../../utis/userInfoContext";

function useCanvasHook() {
    const { rectangles } = useContext(EditorInfoContext)

    const draw = (index, canvasRefs) => {
        const ctx = canvasRefs.current[index].getContext('2d');
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        rectangles[index].forEach(rectangle => {
            drawRectangle(ctx, rectangle);
        });

    };

    const drawRectangle = (ctx, rectangle) => {
        if (!rectangle) return;

        const { startCoords, currentCoords } = rectangle;
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.strokeRect(
            startCoords.x,
            startCoords.y,
            currentCoords.x - startCoords.x,
            currentCoords.y - startCoords.y
        );
    };

    const clearRectangle = (ctx) => {
        ctx.reset()
    }

    const normalizeDimensions = (val, currentScale, desiredScale) => {
        if (undefined === desiredScale) {
            desiredScale = 1;
        }
        return Math.round(val * desiredScale / currentScale);
    };

    return {
        draw,
        normalizeDimensions
    }
}
export default useCanvasHook