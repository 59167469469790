import React from "react"
const PositionSection = ({ tool, handleChange }) => {
    return (
        <>
            <div className="position-section d-flex align-items-center justify-content-between mt-3">
                <label>POSITION</label>
                <div className="d-flex">
                    <div className="me-3">
                        Left: <input
                            type="number"
                            name="geometry.left"
                            value={tool?.geometry?.left}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        Top:
                        <input
                            type="number"
                            name="geometry.top"
                            value={tool?.geometry?.top}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="size-section d-flex justify-content-between">
                <div>
                    Constrain Proportions <input type="checkbox" />
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        Width:
                        <input
                            type="number"
                            name="geometry.width"
                            value={tool?.geometry?.width}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        Height:
                        <input
                            type="number"
                            name="geometry.height"
                            value={tool?.geometry?.height}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="border-mask-section d-flex">
                <div className="toggle-container">
                    <label>Fit within Page Borders</label>
                    <input
                        type="checkbox"
                        id="toggle"
                        className="toggle-input"
                        name='geometry.keep_within_page'
                        value={tool?.geometry?.keep_within_page}
                        onChange={handleChange}
                    />
                    <label htmlFor="toggle" className="toggle-btn"></label>
                </div>
                <div className="toggle-container">
                    <label>Mask Beyond Page Edges</label>
                    <input
                        type="checkbox"
                        id="toggle3"
                        className="toggle-input"
                    />
                    <label htmlFor="toggle3" className="toggle-btn"></label>
                </div>
            </div>
            <div className="transparency-section d-flex">
                <div className="">
                    <p>Transparency</p>
                </div>
                <div className="range-slider">
                    <input
                        id="slider_input"
                        // className="range-slider_input"
                        type="range"
                        min="0"
                        max="100"
                        name='transparency'
                        value={tool?.transparency ?? 1 * 100}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}
export default PositionSection