import React, { useEffect, useState } from "react";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import utilsUploadFormData from "../../../utis/upoadFormData";
import LoadingScreen from "../../Elements/LoadingScreen/LoadingScreen";
import LinkCard from "./LinkCard";
import { Container } from "react-bootstrap";
import apiGETRequest from "../../../utis/apiRequest";
// import { ToastContainer, toast } from 'react-toastify';

const dummyData = [
    {
        "id": 3006,
        "token": "MTcyODYyNjYwMQ",
        "thumbnail": "/storage/docs/MTcyNzM0NzkyNQ/thumb100x150.jpg?1728292852",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNzM0NzkyNQ",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": true,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2957,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 3006,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-11T11:33:22.000+05:30",
            "updated_at": "2024-10-11T11:33:22.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": false,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {},
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-10-07 at 14:50 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "titel 2",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-11 @ 11:33:21 IST",
        "date_updated": "2024-10-11 @ 11:33:21 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODYyNjYwMQ/en-US",
        "viewer": "/viewer/MTcyNzM0NzkyNQ/en-US",
        "stats_path": "/tl-stats/MTcyODYyNjYwMQ",
        "stats": {
            "viewers": "0",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "0%",
            "min_viewed": "--:00:00",
            "returning_viewers": "0",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 0,
            "total_min_viewed": "--:00:00",
            "total_secs_viewed": 0,
            "most_viewed_pages": {
                "pages": [
                    "-"
                ],
                "views": 0
            },
            "pages_by_time_spent": {
                "pages": [
                    "-"
                ],
                "time_spent": "--:00:00"
            },
            "popular_exit_pages": {
                "pages": [
                    "-"
                ],
                "views": 0
            },
            "exit_pages": {
                "pages": [
                    "-"
                ],
                "views": 0
            },
            "page_min_viewed": "--:00:00",
            "time_per_page": [],
            "most_visited_pages": [],
            "most_viewed_pages_tooltip": null,
            "pages_viewed": 0,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [],
            "by_subdivision": [],
            "by_city": [],
            "by_browser": [],
            "by_os": [],
            "by_device": []
        },
        "doc": {
            "id": 24230,
            "group_id": "MTcyNzM0NzkyNQ",
            "title": "heavy file",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-26T16:22:05.000+05:30",
            "updated_at": "2024-10-08T18:21:10.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": true,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/24230",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNzM0NzkyNQ/en-US/thumbs/100x150/0.jpg?1728292852",
        "link_total_sec_viewed": "Total time: --:00:00"
    },
    {
        "id": 3005,
        "token": "MTcyODUzNjMwNQ",
        "thumbnail": "/storage/docs/MTcyNTQ0NTQ2Mg/thumb100x150.jpg?1725445505",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNTQ0NTQ2Mg",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": false,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2956,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 3005,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-10T10:28:25.000+05:30",
            "updated_at": "2024-10-10T10:28:25.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": true,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {
            "lightbox": 54118,
            "frequency": "Each visit",
            "lightbox_name": "Hello",
            "display_enter_device": "On all devices"
        },
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-09-04 at 15:55 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "testing consent",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-10 @ 10:28:25 IST",
        "date_updated": "2024-10-10 @ 10:28:25 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODUzNjMwNQ/en-US",
        "viewer": "/viewer/MTcyNTQ0NTQ2Mg/en-US",
        "stats_path": "/tl-stats/MTcyODUzNjMwNQ",
        "stats": {
            "viewers": "2",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "8%",
            "min_viewed": "--:00:17",
            "returning_viewers": "1",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 17,
            "total_min_viewed": "--:00:17",
            "total_secs_viewed": 17,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 1,
                "page_views": [
                    2
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:09"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 2
            },
            "exit_pages": {
                "1": 2
            },
            "page_min_viewed": "--:00:17",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:08",
                    "secs_on_page": 8.5,
                    "total_min_on_page": "--:00:17",
                    "total_secs_on_page": 17,
                    "views": 2
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 1,
                    "page_views": 2
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 2 visits</br>",
            "pages_viewed": 1,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 2,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 2
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 2,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 2
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 2
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 2
                }
            ],
            "by_os": [
                {
                    "os_family": "Linux",
                    "hits": 2,
                    "drilldown": [
                        {
                            "os": "Linux",
                            "hits": 2
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 2
                }
            ]
        },
        "doc": {
            "id": 21971,
            "group_id": "MTcyNTQ0NTQ2Mg",
            "title": "sep Test",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-04T15:54:22.000+05:30",
            "updated_at": "2024-10-09T17:51:16.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": false,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/21971",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNTQ0NTQ2Mg/en-US/thumbs/100x150/0.jpg?1725445505",
        "last_viewed": "1 day ago",
        "link_total_sec_viewed": "Total time: --:00:17"
    },
    {
        "id": 3004,
        "token": "MTcyODQ3OTExNA",
        "thumbnail": "/storage/docs/MTcyNzM0NzkyNQ/thumb100x150.jpg?1728292852",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNzM0NzkyNQ",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": false,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2955,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 3004,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T18:35:15.000+05:30",
            "updated_at": "2024-10-09T18:35:15.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": true,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {
            "lightbox": 51746,
            "frequency": "Each visit",
            "lightbox_name": "offer",
            "display_enter_device": "On all devices"
        },
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-10-07 at 14:50 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "popup options 1",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:35:14 IST",
        "date_updated": "2024-10-09 @ 18:35:14 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3OTExNA/en-US",
        "viewer": "/viewer/MTcyNzM0NzkyNQ/en-US",
        "stats_path": "/tl-stats/MTcyODQ3OTExNA",
        "stats": {
            "viewers": "2",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "33%",
            "min_viewed": "--:00:34",
            "returning_viewers": "1",
            "engagement": "33%",
            "engagement_val": 33,
            "optins": [],
            "secs_viewed": 34,
            "total_min_viewed": "--:00:34",
            "total_secs_viewed": 34,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 1,
                "page_views": [
                    2
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:17"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 2
            },
            "exit_pages": {
                "1": 2
            },
            "page_min_viewed": "--:00:34",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:17",
                    "secs_on_page": 17,
                    "total_min_on_page": "--:00:34",
                    "total_secs_on_page": 34,
                    "views": 2
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 1,
                    "page_views": 2
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 2 visits</br>",
            "pages_viewed": 1,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 2,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 2
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 2,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 2
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 2
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 2
                }
            ],
            "by_os": [
                {
                    "os_family": "Linux",
                    "hits": 2,
                    "drilldown": [
                        {
                            "os": "Linux",
                            "hits": 2
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 2
                }
            ]
        },
        "doc": {
            "id": 24230,
            "group_id": "MTcyNzM0NzkyNQ",
            "title": "heavy file",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-26T16:22:05.000+05:30",
            "updated_at": "2024-10-08T18:21:10.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": true,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/24230",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNzM0NzkyNQ/en-US/thumbs/100x150/0.jpg?1728292852",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:34"
    },
    {
        "id": 3003,
        "token": "MTcyODQ3ODkwNg",
        "thumbnail": "/storage/docs/MTcyNzM0NzkyNQ/thumb100x150.jpg?1728292852",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNzM0NzkyNQ",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": false,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2954,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 3003,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T18:31:46.000+05:30",
            "updated_at": "2024-10-09T18:31:46.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": true,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {
            "lightbox": 54118,
            "frequency": "Once",
            "lightbox_name": "Hello",
            "display_enter_device": "On all devices"
        },
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-10-07 at 14:50 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "k2k linkingg",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:31:46 IST",
        "date_updated": "2024-10-09 @ 18:31:46 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3ODkwNg/en-US",
        "viewer": "/viewer/MTcyNzM0NzkyNQ/en-US",
        "stats_path": "/tl-stats/MTcyODQ3ODkwNg",
        "stats": {
            "viewers": "1",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "> 67%",
            "min_viewed": "--:02:46",
            "returning_viewers": "0",
            "engagement": "33%",
            "engagement_val": 33,
            "optins": [],
            "secs_viewed": 166,
            "total_min_viewed": "--:02:46",
            "total_secs_viewed": 166,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 2,
                "page_views": [
                    2
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:02:42"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 1
            },
            "exit_pages": {
                "1": 1
            },
            "page_min_viewed": "--:00:55",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:02:42",
                    "secs_on_page": 162,
                    "total_min_on_page": "--:02:42",
                    "total_secs_on_page": 162,
                    "views": 1
                },
                {
                    "page": 3,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                },
                {
                    "page": 2,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 2,
                    "page_views": 2
                },
                {
                    "page": 3,
                    "views": 1,
                    "page_views": 1
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 2 visits</br>",
            "pages_viewed": 2,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 1,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 1,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 1
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 1
                }
            ],
            "by_os": [
                {
                    "os_family": "Windows",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Windows 10",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 1
                }
            ]
        },
        "doc": {
            "id": 24230,
            "group_id": "MTcyNzM0NzkyNQ",
            "title": "heavy file",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-26T16:22:05.000+05:30",
            "updated_at": "2024-10-08T18:21:10.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": true,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/24230",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNzM0NzkyNQ/en-US/thumbs/100x150/0.jpg?1728292852",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:02:46"
    },
    {
        "id": 3002,
        "token": "MTcyODQ3ODc2NQ",
        "thumbnail": "/storage/docs/MTcyNzM0NzkyNQ/thumb100x150.jpg?1728292852",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNzM0NzkyNQ",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": true,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": true,
        "tracking_link_option": null,
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": true,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {
            "lightbox": 54118,
            "frequency": "Each visit",
            "lightbox_name": "Hello",
            "display_enter_device": "On all devices"
        },
        "flash_pop_settings": {},
        "chat_enabled": null,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-10-07 at 14:50 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "Opt-in: Appears on 2nd viewed page  ",
        "expire_time_zone": "Kolkata",
        "name": "k2k linking",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:29:25 IST",
        "date_updated": "2024-10-09 @ 18:29:25 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3ODc2NQ/en-US",
        "viewer": "/viewer/MTcyNzM0NzkyNQ/en-US",
        "stats_path": "/tl-stats/MTcyODQ3ODc2NQ",
        "stats": {
            "viewers": "1",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "33%",
            "min_viewed": "--:00:11",
            "returning_viewers": "0",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 11,
            "total_min_viewed": "--:00:11",
            "total_secs_viewed": 11,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 1,
                "page_views": [
                    1
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:09"
            },
            "popular_exit_pages": {
                "pages": [
                    3
                ],
                "views": 1
            },
            "exit_pages": {
                "3": 1
            },
            "page_min_viewed": "--:00:09",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:09",
                    "secs_on_page": 9,
                    "total_min_on_page": "--:00:09",
                    "total_secs_on_page": 9,
                    "views": 1
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 1,
                    "page_views": 1
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 1 visits</br>",
            "pages_viewed": 1,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 1,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 1,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 1
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 1
                }
            ],
            "by_os": [
                {
                    "os_family": "Windows",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Windows 10",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 1
                }
            ]
        },
        "doc": {
            "id": 24230,
            "group_id": "MTcyNzM0NzkyNQ",
            "title": "heavy file",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-26T16:22:05.000+05:30",
            "updated_at": "2024-10-08T18:21:10.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": true,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/24230",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNzM0NzkyNQ/en-US/thumbs/100x150/0.jpg?1728292852",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:11"
    },
    {
        "id": 3001,
        "token": "MTcyODQ3NzQ4Nw",
        "thumbnail": "/storage/docs/MTcyNTQ0NTQ2Mg/thumb100x150.jpg?1725445505",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNTQ0NTQ2Mg",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": false,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2953,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 3001,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T18:08:07.000+05:30",
            "updated_at": "2024-10-09T18:08:07.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": false,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {},
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            }
        ],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-09-04 at 15:55 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "k2k linking",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:08:07 IST",
        "date_updated": "2024-10-09 @ 18:08:07 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3NzQ4Nw/en-US",
        "viewer": "/viewer/MTcyNTQ0NTQ2Mg/en-US",
        "stats_path": "/tl-stats/MTcyODQ3NzQ4Nw",
        "stats": {
            "viewers": "1",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "13%",
            "min_viewed": "--:00:03",
            "returning_viewers": "0",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 3,
            "total_min_viewed": "--:00:03",
            "total_secs_viewed": 3,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 1,
                "page_views": [
                    1
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:03"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 1
            },
            "exit_pages": {
                "1": 1
            },
            "page_min_viewed": "--:00:03",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:03",
                    "secs_on_page": 3,
                    "total_min_on_page": "--:00:03",
                    "total_secs_on_page": 3,
                    "views": 1
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 1,
                    "page_views": 1
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 1 visits</br>",
            "pages_viewed": 1,
            "element_clicks": {
                "573559": 1
            },
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 1,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 1,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 1
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 1
                }
            ],
            "by_os": [
                {
                    "os_family": "Windows",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Windows 10",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 1
                }
            ]
        },
        "doc": {
            "id": 21971,
            "group_id": "MTcyNTQ0NTQ2Mg",
            "title": "sep Test",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-04T15:54:22.000+05:30",
            "updated_at": "2024-10-09T17:51:16.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": false,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/21971",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNTQ0NTQ2Mg/en-US/thumbs/100x150/0.jpg?1725445505",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:03"
    },
    {
        "id": 3000,
        "token": "MTcyODQ3NzQ2MA",
        "thumbnail": "/storage/docs/MTcyNzM0NzkyNQ/thumb100x150.jpg?1728292852",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNzM0NzkyNQ",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": true,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2952,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 3000,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T18:07:41.000+05:30",
            "updated_at": "2024-10-09T18:07:41.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": false,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {},
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-10-07 at 14:50 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "title",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:07:40 IST",
        "date_updated": "2024-10-09 @ 18:07:40 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3NzQ2MA/en-US",
        "viewer": "/viewer/MTcyNzM0NzkyNQ/en-US",
        "stats_path": "/tl-stats/MTcyODQ3NzQ2MA",
        "stats": {
            "viewers": "1",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "17%",
            "min_viewed": "--:00:08",
            "returning_viewers": "0",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 8,
            "total_min_viewed": "--:00:08",
            "total_secs_viewed": 8,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 1,
                "page_views": [
                    1
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:08"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 1
            },
            "exit_pages": {
                "1": 1
            },
            "page_min_viewed": "--:00:08",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:08",
                    "secs_on_page": 8,
                    "total_min_on_page": "--:00:08",
                    "total_secs_on_page": 8,
                    "views": 1
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 1,
                    "page_views": 1
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 1 visits</br>",
            "pages_viewed": 1,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 1,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 1,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 1
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 1
                }
            ],
            "by_os": [
                {
                    "os_family": "Linux",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Linux",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 1
                }
            ]
        },
        "doc": {
            "id": 24230,
            "group_id": "MTcyNzM0NzkyNQ",
            "title": "heavy file",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-26T16:22:05.000+05:30",
            "updated_at": "2024-10-08T18:21:10.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": true,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/24230",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNzM0NzkyNQ/en-US/thumbs/100x150/0.jpg?1728292852",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:08"
    },
    {
        "id": 2999,
        "token": "MTcyODQ3NzQ0Mg",
        "thumbnail": "/storage/docs/MTcyNTQ0NTQ2Mg/thumb100x150.jpg?1725445505",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNTQ0NTQ2Mg",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": false,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2951,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 2999,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T18:07:23.000+05:30",
            "updated_at": "2024-10-09T18:07:23.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": false,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {},
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            }
        ],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-09-04 at 15:55 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "kdf link",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:07:22 IST",
        "date_updated": "2024-10-09 @ 18:07:22 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3NzQ0Mg/en-US",
        "viewer": "/viewer/MTcyNTQ0NTQ2Mg/en-US",
        "stats_path": "/tl-stats/MTcyODQ3NzQ0Mg",
        "stats": {
            "viewers": "1",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "38%",
            "min_viewed": "--:00:23",
            "returning_viewers": "0",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 23,
            "total_min_viewed": "--:00:23",
            "total_secs_viewed": 23,
            "most_viewed_pages": {
                "pages": [
                    5
                ],
                "views": 3,
                "page_views": [
                    3
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:08"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 1
            },
            "exit_pages": {
                "1": 1
            },
            "page_min_viewed": "--:00:03",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:08",
                    "secs_on_page": 8,
                    "total_min_on_page": "--:00:08",
                    "total_secs_on_page": 8,
                    "views": 1
                },
                {
                    "page": 5,
                    "min_on_page": "--:00:04",
                    "secs_on_page": 4,
                    "total_min_on_page": "--:00:04",
                    "total_secs_on_page": 4,
                    "views": 1
                },
                {
                    "page": 7,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                },
                {
                    "page": 6,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                },
                {
                    "page": 4,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                },
                {
                    "page": 3,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                },
                {
                    "page": 2,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                }
            ],
            "most_visited_pages": [
                {
                    "page": 5,
                    "views": 3,
                    "page_views": 3
                },
                {
                    "page": 1,
                    "views": 2,
                    "page_views": 2
                },
                {
                    "page": 4,
                    "views": 1,
                    "page_views": 1
                }
            ],
            "most_viewed_pages_tooltip": "Page 5 had 3 visits</br>",
            "pages_viewed": 3,
            "element_clicks": {
                "573559": 6
            },
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 1,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 1,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 1
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 1
                }
            ],
            "by_os": [
                {
                    "os_family": "Windows",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Windows 10",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 1
                }
            ]
        },
        "doc": {
            "id": 21971,
            "group_id": "MTcyNTQ0NTQ2Mg",
            "title": "sep Test",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-04T15:54:22.000+05:30",
            "updated_at": "2024-10-09T17:51:16.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": false,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/21971",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNTQ0NTQ2Mg/en-US/thumbs/100x150/0.jpg?1725445505",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:23"
    },
    {
        "id": 2998,
        "token": "MTcyODQ3NzMyNw",
        "thumbnail": "/storage/docs/MTcyMzU0MTgwOQ/thumb100x150.jpg?1723541906",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyMzU0MTgwOQ",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": true,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2950,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 2998,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T18:05:27.000+05:30",
            "updated_at": "2024-10-09T18:05:27.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": false,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {},
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-08-13 at 15:08 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "testing k2k feature",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 18:05:27 IST",
        "date_updated": "2024-10-09 @ 18:05:27 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
        "viewer": "/viewer/MTcyMzU0MTgwOQ/en-US",
        "stats_path": "/tl-stats/MTcyODQ3NzMyNw",
        "stats": {
            "viewers": "3",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "25%",
            "min_viewed": "--:00:21",
            "returning_viewers": "1",
            "engagement": "50%",
            "engagement_val": 50,
            "optins": [],
            "secs_viewed": 21,
            "total_min_viewed": "--:00:21",
            "total_secs_viewed": 21,
            "most_viewed_pages": {
                "pages": [
                    1
                ],
                "views": 1,
                "page_views": [
                    3
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:11"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 3
            },
            "exit_pages": {
                "1": 3
            },
            "page_min_viewed": "--:00:21",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:07",
                    "secs_on_page": 7,
                    "total_min_on_page": "--:00:21",
                    "total_secs_on_page": 21,
                    "views": 3
                }
            ],
            "most_visited_pages": [
                {
                    "page": 1,
                    "views": 1,
                    "page_views": 3
                }
            ],
            "most_viewed_pages_tooltip": "Page 1 had 3 visits</br>",
            "pages_viewed": 1,
            "element_clicks": {},
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 3,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 3
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 3,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 3
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 3
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 3
                }
            ],
            "by_os": [
                {
                    "os_family": "Linux",
                    "hits": 2,
                    "drilldown": [
                        {
                            "os": "Linux",
                            "hits": 2
                        }
                    ]
                },
                {
                    "os_family": "Windows",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Windows 10",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 3
                }
            ]
        },
        "doc": {
            "id": 19620,
            "group_id": "MTcyMzU0MTgwOQ",
            "title": "Testing 1",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 2",
            "user_id": 4310,
            "created_at": "2024-08-13T15:06:49.000+05:30",
            "updated_at": "2024-10-09T18:05:27.000+05:30",
            "modifier_id": null,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": false,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/19620",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyMzU0MTgwOQ/en-US/thumbs/100x150/0.jpg?1723541906",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:21"
    },
    {
        "id": 2997,
        "token": "MTcyODQ3NjQ3Ng",
        "thumbnail": "/storage/docs/MTcyNTQ0NTQ2Mg/thumb100x150.jpg?1725445505",
        "page_viewed_sec": 2,
        "cyoa_enabled": false,
        "domain": "powered-by.kinetizine.com",
        "group_id": "MTcyNTQ0NTQ2Mg",
        "tracking_link_permission": true,
        "landing_page": 1,
        "show_toolbar": true,
        "enable_download": false,
        "seo_description": "I'm sharing this with you because...",
        "show_thumbnail": false,
        "zoom_lvl": "page-height",
        "bot_visits_count": 0,
        "tl_user_id": 4310,
        "visits_count_class": false,
        "send_engagement_notif": true,
        "kdf_action_link": false,
        "notify_after": 22,
        "notify_for_page": null,
        "notify_page_after": null,
        "is_company_user": true,
        "is_customization": false,
        "user_company_name": "Nirag Info Tech",
        "company_name": "Nirag Infotech",
        "company_privay_url": "",
        "sender_name": "Jawahar Lamba",
        "first_name": "Jawahar",
        "privacy_setting": "do_not_show",
        "has_optin": false,
        "tracking_link_option": {
            "id": 2949,
            "user_id": 4310,
            "modifier_id": null,
            "tracking_link_id": 2997,
            "show_on_page": 2,
            "show_delay": 1,
            "ask_name": true,
            "ask_email": true,
            "ask_company": false,
            "custom_text": "To continue reading, please provide your:",
            "custom_btn_text": "OK",
            "show_lightbox_on_enter": false,
            "lightbox_on_first_launch_only": true,
            "lightbox_on_enter_text": "Feel free to share this doc.",
            "created_at": "2024-10-09T17:51:16.000+05:30",
            "updated_at": "2024-10-09T17:51:16.000+05:30",
            "show_info_share_with": false,
            "show_sender_info": true,
            "ask_telephone_number": false,
            "custom_subsequent_text": "[FIRST NAME], please provide your:",
            "chat_enabled": false
        },
        "optin_2nd_page_viewed": true,
        "template": false,
        "language_flag": {
            "name": "English (US)",
            "class": "flag-icon flag-icon-us",
            "locale": "en-US"
        },
        "tracking_link_company": {
            "id": 108,
            "name": "Nirag Info Tech",
            "company_type": 9,
            "company_id": 2,
            "trial": 10,
            "licences": 9,
            "licence_threshold": 8,
            "payment_threshold": 33,
            "user_id": 3,
            "modifier_id": 4310,
            "created_at": "2024-07-27T16:58:25.000+05:30",
            "updated_at": "2024-10-10T16:49:53.000+05:30",
            "view_base_url": null,
            "pp_text": null,
            "pp_image": null
        },
        "disable_scroll": true,
        "disable_settings": {
            "disable_on": "seconds",
            "disable_for": 3,
            "disable_message": "This document will be scrollable in"
        },
        "exit_pop": false,
        "enter_pop": false,
        "flash_pop": false,
        "default_flash_pop": false,
        "default_exit_pop": false,
        "default_enter_pop": false,
        "exit_pop_settings": {},
        "enter_pop_settings": {},
        "flash_pop_settings": {},
        "chat_enabled": false,
        "enable_transparent_bg": true,
        "enable_shadow_box": true,
        "is_chat_active": false,
        "clicks": [
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            },
            {
                "id": 573559,
                "doc_id": 21971,
                "docs_file_id": 22005,
                "docs_lightbox_id": null,
                "page": 1,
                "order_on_page": 1,
                "element": null,
                "element_type": 4,
                "value": {
                    "url": "https://powered-by.kinetizine.com/MTcyODQ3NzMyNw/en-US",
                    "gif_link": "https://i.giphy.com/media/ZGBQhaRTHyWtRVn1Xx/giphy.gif",
                    "timerField": "ViewPort",
                    "kdf": 19620,
                    "kdf_title": "Testing 1",
                    "link": 2998,
                    "link_title": "testing k2k feature"
                },
                "file": {
                    "url": null
                },
                "file_details": {},
                "updates": 7,
                "geometry": {
                    "left": 442,
                    "top": 475,
                    "width": 132,
                    "height": 132,
                    "keep_proportions": true,
                    "keep_within_page": true
                },
                "user_id": 4310,
                "modifier_id": null,
                "created_at": "2024-09-23T16:29:25.000+05:30",
                "updated_at": "2024-10-09T18:06:48.000+05:30",
                "rotate": 0,
                "flip": 1,
                "sec_to_delay": 0,
                "disappear_after": 0,
                "is_tenor": false,
                "image_text": "",
                "clone_element_id": null,
                "image_type": "",
                "gif_type": "sticker",
                "priority": false,
                "transparency": "1.0",
                "recurrence": "OncePerVisit",
                "dropdown_data": {}
            }
        ],
        "desired_action_frequencies_per_tl": [],
        "privacy_policy_consent": true,
        "doc_file_status": "active",
        "doc_file_updated_at": "2024-09-04 at 15:55 IST",
        "profile": {
            "id": 337,
            "first_name": "Jawahar",
            "last_name": "Lamba",
            "phone": "",
            "time_zone": "Kolkata",
            "user_id": 4310,
            "created_at": "2024-07-10T21:59:40.000+05:30",
            "updated_at": "2024-07-10T22:02:01.000+05:30",
            "direct_chat_link": "",
            "privacy_link": "",
            "company_name": "Nirag Infotech",
            "country": "India"
        },
        "tags": [],
        "expiry": null,
        "tooltip_expire_time": "",
        "expire_time_zone": "Kolkata",
        "name": "testing hotspot feature",
        "campaign": null,
        "password": null,
        "username": "Jawahar Lamba",
        "date_created": "2024-10-09 @ 17:51:16 IST",
        "date_updated": "2024-10-09 @ 17:51:16 IST",
        "tracking_link": "https://powered-by.kinetizine.com/MTcyODQ3NjQ3Ng/en-US",
        "viewer": "/viewer/MTcyNTQ0NTQ2Mg/en-US",
        "stats_path": "/tl-stats/MTcyODQ3NjQ3Ng",
        "stats": {
            "viewers": "1",
            "unique_viewers": "0",
            "optins_num": "0",
            "percent_viewed": "38%",
            "min_viewed": "--:00:36",
            "returning_viewers": "0",
            "engagement": "0%",
            "engagement_val": 0,
            "optins": [],
            "secs_viewed": 36,
            "total_min_viewed": "--:00:36",
            "total_secs_viewed": 36,
            "most_viewed_pages": {
                "pages": [
                    4
                ],
                "views": 5,
                "page_views": [
                    5
                ]
            },
            "pages_by_time_spent": {
                "pages": [
                    1
                ],
                "time_spent": "--:00:13"
            },
            "popular_exit_pages": {
                "pages": [
                    1
                ],
                "views": 1
            },
            "exit_pages": {
                "1": 1
            },
            "page_min_viewed": "--:00:05",
            "time_per_page": [
                {
                    "page": 1,
                    "min_on_page": "--:00:13",
                    "secs_on_page": 13,
                    "total_min_on_page": "--:00:13",
                    "total_secs_on_page": 13,
                    "views": 1
                },
                {
                    "page": 4,
                    "min_on_page": "--:00:06",
                    "secs_on_page": 6,
                    "total_min_on_page": "--:00:06",
                    "total_secs_on_page": 6,
                    "views": 1
                },
                {
                    "page": 3,
                    "min_on_page": "--:00:04",
                    "secs_on_page": 4,
                    "total_min_on_page": "--:00:04",
                    "total_secs_on_page": 4,
                    "views": 1
                },
                {
                    "page": 2,
                    "min_on_page": "--:00:04",
                    "secs_on_page": 4,
                    "total_min_on_page": "--:00:04",
                    "total_secs_on_page": 4,
                    "views": 1
                },
                {
                    "page": 6,
                    "min_on_page": "--:00:03",
                    "secs_on_page": 3,
                    "total_min_on_page": "--:00:03",
                    "total_secs_on_page": 3,
                    "views": 1
                },
                {
                    "page": 5,
                    "min_on_page": "--:00:03",
                    "secs_on_page": 3,
                    "total_min_on_page": "--:00:03",
                    "total_secs_on_page": 3,
                    "views": 1
                },
                {
                    "page": 7,
                    "min_on_page": "--:00:02",
                    "secs_on_page": 2,
                    "total_min_on_page": "--:00:02",
                    "total_secs_on_page": 2,
                    "views": 1
                }
            ],
            "most_visited_pages": [
                {
                    "page": 4,
                    "views": 5,
                    "page_views": 5
                },
                {
                    "page": 1,
                    "views": 3,
                    "page_views": 3
                },
                {
                    "page": 6,
                    "views": 2,
                    "page_views": 2
                }
            ],
            "most_viewed_pages_tooltip": "Page 4 had 5 visits</br>",
            "pages_viewed": 3,
            "element_clicks": {
                "573559": 12
            },
            "element_video_stats": {},
            "complete_video_element_watched": {
                "hundres_percent_watched": 0,
                "total_clicks": 0
            },
            "by_country": [
                {
                    "country": "India",
                    "hits": 1,
                    "drilldown": [
                        {
                            "subdivision": "Chandigarh",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_subdivision": [
                {
                    "subdivision": "Chandigarh",
                    "hits": 1,
                    "drilldown": [
                        {
                            "city": "Chandigarh, CH, IN",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_city": [
                {
                    "city": "Chandigarh, CH, IN",
                    "hits": 1
                }
            ],
            "by_browser": [
                {
                    "browser": "Chrome",
                    "hits": 1
                }
            ],
            "by_os": [
                {
                    "os_family": "Windows",
                    "hits": 1,
                    "drilldown": [
                        {
                            "os": "Windows 10",
                            "hits": 1
                        }
                    ]
                }
            ],
            "by_device": [
                {
                    "device": "Desktop",
                    "hits": 1
                }
            ]
        },
        "doc": {
            "id": 21971,
            "group_id": "MTcyNTQ0NTQ2Mg",
            "title": "sep Test",
            "visibility": "to_all",
            "page_viewed_sec": 2,
            "engagement_pages": "1 - 8",
            "user_id": 4310,
            "created_at": "2024-09-04T15:54:22.000+05:30",
            "updated_at": "2024-10-09T17:51:16.000+05:30",
            "modifier_id": 4310,
            "company_id": 108,
            "note": null,
            "is_sample": false,
            "seo_description": null,
            "private_flag": false,
            "timer_on_doc": true,
            "exit_intent_value": {},
            "enter_intent_value": {},
            "flash_intent_value": {}
        },
        "doc_path": "/kdfs/21971",
        "language": "en-US",
        "receivers": [],
        "thumb": "/storage/docs/MTcyNTQ0NTQ2Mg/en-US/thumbs/100x150/0.jpg?1725445505",
        "last_viewed": "2 days ago",
        "link_total_sec_viewed": "Total time: --:00:36"
    }
];
const LinkPage = () => {
    const [linkListData, setLinkListData] = useState({ loading: true, data: [] });
    const itemsPerPage = 10;
    const updateLaserLinksData = async (page = 1) => {
        let getValue = {
            "page": page,
            "per_page": itemsPerPage,
            "is_filter": true
        }
        let getLaserLink = await utilsUploadFormData('links/search.json', getValue, 'POST');

        // setLinkListData({...getLaserLink, data: getLaserLink.tracking_links, loading: false, "page": page, "per_page": itemsPerPage})
        setLinkListData({ ...getLaserLink, data: dummyData, loading: false, "page": page, "per_page": itemsPerPage })
        // console.log('getLaserLink ', getLaserLink);
    }

    const handleCopyLaserLinkEvent = async (listingID) => {
        let copyLaserLink = await apiGETRequest(`/links/clone/${listingID}.json`);
        // if(copyLaserLink.msg == 'ok'){
        //     toast.success('🦄 Wow so easy!', {
        //         position: "top-center",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //         transition: Bounce,
        //         });
        // }
    }

    useEffect(() => {
        updateLaserLinksData();
    }, [])
    return (
        <>
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-title">Filters</h4>
                    <form className="">
                        <div className="d-flex">
                            <div className="form-group">
                                <input className="kdf-Tags3 ms-0" type="email" placeholder="Recipients..." />
                            </div>
                            <div className="d-flex form-group">
                                <input className="kdf-Tags3" type="text" placeholder="LaserLINK URL" />
                            </div>
                            <div className="d-flex form-group">
                                <input className="kdf-Tags3" type="text" placeholder="Campaigns" />
                            </div>
                            <div className="d-flex form-group">
                                <input className="kdf-Tags2" type="text" placeholder="LaserLINK Tags" />
                            </div>
                        </div>
                        <div className="d-flex mt-3">
                            <div className="d-flex form-group">
                                <input className="kdf-Tags2  ms-0" type="text" placeholder="LaserLINK Title" />
                            </div>
                            <div className="d-flex form-group">
                                <input className="kdf-Tags2  " type="text" placeholder="KDF Title" />
                            </div>

                            <button className="btn btn-info margin-left-15 reset">
                                Apply Filters
                            </button>
                            <button className="reset-btn ">
                                Reset Filters
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {
                linkListData.loading ?
                    <LoadingScreen />
                    :
                    (
                        linkListData?.data?.length > 0 ?
                            <Container style={{ maxWidth: '800px', margin: '30px auto 0px' }}>
                                <Paginate
                                    currentPage={linkListData?.page}
                                    totalPages={Math.ceil(linkListData?.cnt / linkListData?.per_page)}
                                    onPageChange={updateLaserLinksData}
                                    totalData={linkListData?.cnt}
                                    itemTitle={'LaserLINX'}
                                />
                                {
                                    linkListData?.data?.map(
                                        (linkData, linkDataIndex) =>
                                            <LinkCard data={linkData} key={linkDataIndex} trigerCopyLaserLinkEvent={handleCopyLaserLinkEvent} />
                                    )
                                }
                                <Paginate
                                    currentPage={linkListData?.page}
                                    totalPages={Math.ceil(linkListData?.cnt / linkListData?.per_page)}
                                    onPageChange={updateLaserLinksData}
                                    totalData={linkListData?.cnt}
                                    itemTitle={'LaserLINX'}
                                />
                            </Container>
                            :
                            <div className="alert w-100 d-flex p-4">
                                Sorry, We tried our best but could NOT find a LaserLINK matching the filter you used. Please reset the filter and try again.
                            </div>
                    )
            }

        </>
    )
}
export default LinkPage;
