import React from "react"
import './PdfSidebar.css'

const PdfSidebar = ({ pdfImages }) => {
    return (
        <div className="pdf-sidebar text-light " >
            {
                pdfImages?.map((url, idx) => {
                    return (
                        <div className={`image-side-listing mb-2 mt-2 ${idx == 0 && 'active-page-image'}`} >
                            <div className="pdf-side-img">
                                <img src={url} alt="page image" />
                            </div>
                            <p>{idx + 1}</p>
                        </div>
                    )
                })
            }
        </div >
    )
}
export default PdfSidebar