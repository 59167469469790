import React from "react";
import Breadcrumb from "../../CommonComponents/BreadCrumb/BreadCrumb";
const AdminUsersPage = () => {
    return (
        <>
            <Breadcrumb string={'Admin/Users'} />
            <div className="container">
                <div className="show-select1 d-flex ">
                    <div className="d-flex align-items-center">
                        <p>Show</p>
                        <div className="custom-select-container me-2 ms-2 ali">
                            {/* <!-- <label htmlFor="custom-select">Choose an option:</label> --> */}
                            <select id="custom-select" className="custom-select">
                                <option value="option1"> 10</option>
                                <option value="option2"> 25</option>
                                <option value="option3"> 50</option>
                                <option value="option4"> 100</option>
                            </select>
                        </div>
                        <p>entries</p>
                        <div className="custom-select-container me-2 ms-4 ali">
                            {/* <!-- <label htmlFor="custom-select">Choose an option:</label> --> */}
                            <select id="custom-select" className="custom-select">
                                <option value="option1"> Select Action </option>
                                <option value="option2"> Delete Seleted</option>
                                <option value="option3"> Resend Signup Verification</option>
                            </select>
                        </div>
                    </div>

                    <div className="Search-inp">
                        <div>
                            <label>
                                Search :
                            </label>
                            <input placeholder="Search..." />
                        </div>
                    </div>
                </div>
                <div className="top-parent">
                    <table className="Subscription-table mt-4">
                        <tr>
                            <th>

                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Email
                            </th>

                            <th className="hov-clr">
                                Last Login
                            </th>
                            <th className="hov-clr">
                                Country
                            </th>
                            <th className="hov-clr">
                                PP Acceptance on
                            </th>
                            <th className="hov-clr">
                                ToS Acceptance on
                            </th>
                            <th>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <input type="checkbox" />
                            </td>
                            <td>
                                Ted Box
                            </td>
                            <td>
                                t@boxonline.com
                            </td>
                            <td className="hov-clr">
                                2024-07-16 @ 10:36:47 IST
                            </td>
                            <td className="hov-clr">
                                Switzerland
                            </td>
                            <td className="hov-clr">
                                2021-09-27T18:51:27.000+05:30
                            </td>
                            <td className="hov-clr">
                                2020-01-01T05:30:00.000+05:30
                            </td>
                            <td className="hov-clr users-icons">
                                <a href="#"><i className="fa-solid fa-trash-can me-2"></i></a>
                                <a href="#"><i className="fa-solid fa-user-group me-2"></i></a>
                                <a href="#"><i className="fa-solid fa-circle user-active"></i></a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="showing-main d-flex justify-content-between mt-3">
                    <div className="showing-para">
                        <p>Showing 1 to 10 of 3,837 entries</p>
                    </div>
                    <div>
                        <button className="previous-btn me-2">Previous</button>
                        <span className="count-btn me-2">1</span>
                        <span className="count-btn me-2">2</span>
                        <span className="count-btn me-2">3</span>
                        <span className="count-btn me-2">4</span>
                        <span className="count-btn me-2">5</span>
                        <span className="count-btn me-2">...</span>
                        <span className="count-btn me-2">384</span>
                        <button className="previous-btn">Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminUsersPage;
