import React from "react"
import './VideoSection.css'

const VideoSection = ({ handleVideoUrlUpload, videoLink }) => {
    return (
        <div class="video-input-main">
            <div>
                <input placeholder="Enter video embed code"
                    value={videoLink}
                    onChange={(e) => handleVideoUrlUpload(e, 'video')}
                />
            </div>
            <div class="video-top-para mt-2">
                <p>
                    <em>
                        Use Vimeo's or Youtube's embed code (not the video url)
                    </em>
                </p>
            </div>
        </div>
    )
}
export default VideoSection