import React from "react"
const TextEditorSection = () => {
    return (
        <>
            TextEditorSection
        </>
    )
}
export default TextEditorSection
// targets: {
//     browsers: "> 0.25%, not dead"
//   },
// import React from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import {
//     ClassicEditor,
//     Bold,
//     Essentials,
//     Heading,
//     Indent,
//     IndentBlock,
//     Italic,
//     Link,
//     List,
//     MediaEmbed,
//     Paragraph,
//     Table,
//     Undo
// } from 'ckeditor5';

// import 'ckeditor5/ckeditor5.css';

// export default function TextEditorSection() {
//     return (
//         <CKEditor
//             editor={ClassicEditor}
//             config={{
//                 toolbar: [
//                     'undo', 'redo', '|',
//                     'heading', '|', 'bold', 'italic', '|',
//                     'link', 'insertTable', 'mediaEmbed', '|',
//                     'bulletedList', 'numberedList', 'indent', 'outdent'
//                 ],
//                 plugins: [
//                     Bold,
//                     Essentials,
//                     Heading,
//                     Indent,
//                     IndentBlock,
//                     Italic,
//                     Link,
//                     List,
//                     MediaEmbed,
//                     Paragraph,
//                     Table,
//                     Undo
//                 ],
//                 initialData: '<h1>Hello from CKEditor 5!</h1>',
//             }}
//         />
//     );
// }
