import React from 'react';
import {Form} from 'react-bootstrap';

const Select = ({classList,required = false, errorMessage, errorMessageStatus, children, label, defaultValue = '', optionList, ...restProps}) => {
    return(
        <Form.Select aria-label={`Default select ${{...restProps}?.name}`} className={`${classList != undefined ? classList : ''}`} value={defaultValue} required={required} {...restProps}>
            <option value="">Select..</option>
            {
                children ? 
                {children}
                :
                optionList?.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)

            }
            {/* <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option> */}
        </Form.Select>
    )
}

export default Select;