import React, { useState, useEffect } from "react";
import Paginate from "../../CommonComponents/Pagination/Paginate";
import customAPIRequest from "../../../utis/customAPIRequest";
import KdfListCard from "./EditKdf/KdfListCard";
import { Container } from "react-bootstrap";
import styles from './KdfListingPage.module.css';
import utilsUploadFormData from "../../../utis/upoadFormData";
import CommonModal from "../../Modals/CommonModal";


const KdfListPage = ({ props }) => {
    // console.log(props.doc);
    const itemsPerPage = 10;
    const [docData, setDocData] = useState({});
    const handleGetKDFDocData = async (page = 1) => {        
        let getAllDocData = await customAPIRequest('docs-search.json', 'POST', 
            {
                "page":page,
                "per_page":itemsPerPage
            }
        );
        setDocData({...getAllDocData,"page": page, "per_page": itemsPerPage});
    }
    useEffect(() => {
        handleGetKDFDocData();
    },[])

    // Update Tags start 

    const handleUpdateDocData = async (dataToUpdate = [], itemID) => {
        try{
            let uploadData = await utilsUploadFormData(`kdfs/${itemID}.json`,{doc:{'tag_ids':dataToUpdate}}, 'PATCH');
            handleGetKDFDocData();
        }catch(error) {
            console.warn(error);
        }
    }

    const handeAddKDFTagsEvent = async (value, data) => {
        let valueToUpdate = [];
        if(value.length > data?.tags.length){            
            let updateTagsToDoc = await utilsUploadFormData(`tags.json`, {'name':value[value.length -1]}, 'POST');
            valueToUpdate = [...data?.tags?.map(item => item.id),updateTagsToDoc.data.id ]
        }else{
            valueToUpdate = data?.tags.filter(item => value.includes(item.name))?.map(item => item.id);
        }
        handleUpdateDocData(valueToUpdate);
    }

    const updateRecordData = (value, data, type) => {
        if(type == 'tags'){            
            handeAddKDFTagsEvent(value, data)
        }
    }

    // Update Tags end

    return (
        <div>
            <div className="header-content-main">
                <div className="doc-filter">
                    <h4 className="filter-title">Filters</h4>
                    <form className="d-flex">
                        <div className="d-flex form-group">
                            <input className="kdf-title" type="text" placeholder="KDF Title" />
                        </div>
                        <div className="all-btn ms-2">
                            <div className="dropdown">
                                <a className="btn btn-secondary dropdown-toggle" href="#" role="button"
                                    id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    All
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><a className="dropdown-item" href="#">All</a></li>
                                    <li><a className="dropdown-item" href="#">Published</a></li>
                                    <li><a className="dropdown-item" href="#">Unpublished</a></li>
                                    <li><a className="dropdown-item" href="#">Private</a></li>
                                    <li><a className="dropdown-item" href="#">Public</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="d-flex form-group">
                            <input className="kdf-Tags" type="text" placeholder="KDF Tags" />
                        </div>
                        <button className="btn btn-info margin-left-15 reset">
                            Apply Filters
                        </button>
                        <button className="reset-btn">
                            Reset Filters
                        </button>
                    </form>
                </div>
            </div>
            <Container className={styles.listingContainer}>
                {docData?.data?.docs != undefined && docData?.data?.docs?.length > 0 ?
                    <>
                        <Paginate
                            currentPage={docData?.page}
                            totalPages={Math.ceil(docData?.data?.cnt / docData?.per_page)}
                            onPageChange={handleGetKDFDocData}
                            totalData={docData?.data?.cnt}
                            itemTitle={'KDFs'}
                        />
                        {docData?.data?.docs?.map((kdf, index) => 
                            <KdfListCard 
                                key={index} 
                                data={kdf} 
                                triggerUdateRecord={() => handleGetKDFDocData()}
                                triggerUpdateRecordData={updateRecordData}
                            />
                        )}
                        <Paginate
                            currentPage={docData?.page}
                            totalPages={Math.ceil(docData?.data?.cnt / docData?.per_page)}
                            onPageChange={handleGetKDFDocData}
                            totalData={docData?.data?.cnt}
                            itemTitle={'KDFs'}
                        /> 
                    </>
                    :
                    docData?.error != undefined ? docData?.error : (docData?.data?.docs?.length > 0 ? "No record Found" : 'Loading...')
                }
            </Container>
        </div>
    )
}
export default KdfListPage;


