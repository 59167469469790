import React, { useContext } from 'react';
import ImageToolbox from './toolBox/ImageToolbox';
import VideoToolbox from './toolBox/VideoToolbox';
import TextToolbox from './toolBox/TextToolbox';
import EmbedToolbox from './toolBox/EmbedToolbox';
import HotspotToolbox from './toolBox/HotspotToolbox';
import LightboxesToolbox from './toolBox/LightboxesToolbox';
import './EditorToolbox.css';
import { EditorInfoContext } from '../../../../../utis/userInfoContext';

const EditorToolbox = () => {
    const { setEditEnabled, setToolType, setOpenAddElement } = useContext(EditorInfoContext)

    const handleToolboxSelectEvent = (status) => {
        if (status !== 'LightboxesToolbox') {
            setEditEnabled(true)
        } else {
            setOpenAddElement(true);
        }
        setToolType(status)
    }

    return (
        <div className="editorToolbox__popup">
            <div className="editorToolbox__content">
                <div className="editorToolbox__gridContainer">
                    <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('ImageToolbox')}>
                        <ImageToolbox />
                    </div>
                    <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('VideoToolbox')}>
                        <VideoToolbox />
                    </div>
                    <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('TextToolbox')}>
                        <TextToolbox />
                    </div>
                    <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('EmbedToolbox')}>
                        <EmbedToolbox />
                    </div>
                    <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('HotspotToolbox')}>
                        <HotspotToolbox />
                    </div>
                    <div className="editorToolbox__gridItem" onClick={() => handleToolboxSelectEvent('LightboxesToolbox')}>
                        <LightboxesToolbox />
                    </div>
                </div>
                <div className="tolbox-btns">
                    <div className="btn-grp1 d-flex">
                        <button className="editorToolbox__button editorToolbox__button--secondary me-3">Preview Changes</button>
                        <button className="editorToolbox__button editorToolbox__button--primary">Republish</button>
                    </div>
                    <button className="editorToolbox__button editorToolbox__button--secondary1">Preview Published
                        Version</button>
                </div>
                <div className="d-flex lower-toolbox-btn">
                    <button className="editorToolbox__button editorToolbox__button--tertiary me-2">Settings</button>
                    <button className="editorToolbox__button editorToolbox__button--tertiary">Support</button>
                </div>

                <div className="editorToolbox__checkboxContainer">
                    <input type="checkbox" id="showTags" />
                    <label htmlFor="showTags">Show page tags outside the page</label>
                </div>
                <div className="editorToolbox__checkboxContainer">
                    <input type="checkbox" id="deactivateTimer" />
                    <label htmlFor="deactivateTimer">Deactivate the element timer</label>
                </div>
            </div>
        </div>
    )
}
export default EditorToolbox;
