import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from '../components/Authentication/Login';
import Signup from '../components/Authentication/Signup';
import ErrorPage from './Pages/ErrorPage';
import MainLayout from './Layout/MainLayout';
import Customizations from './Pages/Customization/Customizations';
import CreateNewKdf from './Pages/Kdf/CreateNewKdf';
import KdfSettings from './Pages/Kdf/EditKdf/KdfSettings';
import KdfListPage from './Pages/Kdf/KdfListPage';
import EditKdfPage from './Pages/Kdf/EditKdf/EditKdfPage';
import LinkPage from './Pages/Links/LinkPage';
const MainBody = (props) => {
    const appRouter = createBrowserRouter([
        {
            path:'/',
            element:<Login status='login' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/users/login',
            element:<Login status='login' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/users/confirmation/new',
            element:<Login status='confirmation' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/users/confirmation',
            element:<Login status='confirmation' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/users/unlock/new',
            element:<Login status='unlock' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/users/unlock',
            element:<Login status='unlock' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'users/password/new',
            element:<Login status='forgotPassword' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'users/password',
            element:<Login status='forgotPassword' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/signup',
            element:<Login status='signup' props={props} />,
            errorElement: <ErrorPage />
        },
        {
            path:'/kdfs',
            element:<MainLayout header='KDFs' props={props} >
                <KdfListPage props={props} />
            </MainLayout>,
            errorElement: <ErrorPage />
        },
        {
            path:'/customizations',
            element:<MainLayout header='Consent Modal Change Requests' props={props} ><Customizations {...props} /></MainLayout>,
            errorElement: <ErrorPage />
        },
        {
            path:'/kdfs/new',
            element:<MainLayout header='Create New' props={props} >
                <CreateNewKdf props={props}/>
            </MainLayout>,
            errorElement: <ErrorPage />
        },
        {
            path:'/kdfs/:id',
            element:
            <MainLayout header='Create New' props={props} >
                <KdfSettings {...props} />
            </MainLayout>,
            errorElement: <ErrorPage />
        },
        {
            path:'/editor/:id',
            element:<EditKdfPage {...props} /> ,
            errorElement: <ErrorPage />
        },
        {
            path:'/links',
            element:
            <MainLayout header='Links' props={props} >
                <LinkPage {...props} />
            </MainLayout>,
            errorElement: <ErrorPage />
        },
        
    ]);
    return(<RouterProvider router={appRouter} />)
}
export default MainBody;