import React from "react";
import { faArrowsUpDownLeftRight, faCircleXmark, faCopy, faFloppyDisk, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ElementIcons = ({ handleDelete, element, dimensions }) => {
    // style={{ top: `${dimensions.top}px` }}
    return (
        <>
            <div className="action-icons-top d-flex" >
                <FontAwesomeIcon
                    icon={faTrashCan}
                    className='cancel'
                    onClick={() => handleDelete(element?.id)}
                />
                <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="cancel"
                />
                <FontAwesomeIcon
                    icon={faPencil}
                    className="success-green"
                />
                <FontAwesomeIcon
                    icon={faCopy}
                    className=""
                />
                <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="success-green"
                />
            </div>
            <div className='action-icons-bottom'>
                <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            </div>
        </>
    )
}

export default ElementIcons