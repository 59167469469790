import React, { useEffect, useState } from 'react';
import { csrfToken } from './csrfTokenUtils';

const getTimeZone = () => {
    const [timeZoneData, setTimeZoneData] = useState({"loading":true});
    const fetchUserData = async () => {
        try {
            const response = await fetch(`/time-zones`, {
                method: 'GET',
                cookie: {
                    'X-CSRF-Token': csrfToken(),
                },
            });
            const responseJSON = await response.json();
            setTimeZoneData({"loading":false, "data":responseJSON});
        } catch (error) {
            setTimeZoneData({"loading":false, "error":error});
        }
    }

    useEffect(() => {
        fetchUserData();
        return () => {
            setTimeZoneData({"loading":true});
        }
    },[])
    return timeZoneData;
}

export default getTimeZone;