import React, { useState, useEffect, useRef } from 'react';
import kz_logo from '../../../assets/images/kz_logo.png';
import gdpr_badge from '../../../assets/images/gdpr-badge.png';
import { getTranslation } from '../../hooks/utils';
import Button from '../../components/Elements/Button';
import Input from '../../components/Elements/Input';
import TermsAndPolicyModal from '../Modals/TermsAndPolicyModal';
import PrivacyNotice from '../../utis/PrivacyNotice';
import TermsAndUseContent from '../../utis/TermsAndUseContent';
import './authenticationStyles.css';
import { useLocation } from 'react-router-dom';
import DropDown from '../Elements/DropDown';
import { csrfToken } from '../../utis/csrfTokenUtils';

const gettanslationStringOnBehalfOfStatus = {"login":'devise.log_in_header', 'confirmation':'devise.headers.resend_confirmation_instructions', "unlock" : 'devise.headers.resend_unlock_instructions', "forgotPassword": 'devise.headers.recover_password', "signup":'devise.logo_texts.try_kz_free'};
const loginErrorMessages = ['user_exist', 'invalid_password', 'not_found_in_database'];
const formActionURL = {"login":'/users/login', 'confirmation':'/users/confirmation', 'unlock': '/users/unlock', 'forgotPassword': '/users/password' };

const GetMessage = ({message}) => {
  let content;
  switch (message) {
    case 'locked':
    case 'send_instructions':
    case 'invalid_password':
      content = (
        <>
          {getTranslation('devise.failure.locked1_html')}
          Please <a href="https://support.boxonline.com">contact us</a> to reactivate it
        </>
      );
      break;
    case 'last_attempt':
      content = getTranslation('devise.failure.last_attempt1');
      break;
    default:
      content = "";
  }
  return <>{content}</>;
}


const Login = ({ props, status }) => {
  const [termsModalStatus, setTermsModalStatus] = useState(false);
  const [policyModalStatus, setPolicyModalStatus] = useState(false);
  const { error, messages } = props;
  const getAlertMessage = (type, error) => {
    if(Object.keys(error)?.length > 0 & error[type] != undefined){
      return <span className='has-error'>{error[type].join('')}</span>
    }
    return '';
  }
 return (
    <div className='login-form-container'>
      <form className="form-main-auth text-center" action={formActionURL[status] != undefined ? formActionURL[status] : ''} method="post">
        <input type='hidden' value={csrfToken()} name='authenticity_token'/>
        <img src={kz_logo} alt="Logo" className='login-form-logo'/>
        <div className='login-form-auth-wrapper'>
        {
          gettanslationStringOnBehalfOfStatus[status] != undefined &&
          <div className={`login-form-heading ${status == 'signup' ? 'signup-header' : ''}`}>
          {
            getTranslation(gettanslationStringOnBehalfOfStatus[status])
          }
        </div>}
        {
          status == 'signup' &&
          <div className="signup-alert">
           {
            `${getTranslation('devise.logo_texts.no_card')} ${getTranslation('devise.logo_texts.cancel_anytime')}`
           }
          </div>
        }
        {props?.messages?.alert != undefined && <div className={`d-flex justify-start alert-devise`}>
            <GetMessage message={props?.messages?.alert} />          
        </div>}
          <Input 
            classList={(status == 'confirmation' || status == 'unlock') ? 'mt-4 pt-4' : ''}
            type="email" 
            placeholder="Email" 
            name="user[email]" 
            required={true} 
            errorMessageStatus={error != null && Object.keys(error)?.length > 0 && error['email'] != undefined}
            errorMessage={
              messages.alert == 'Please click on the link in the email we just sent you.' ? 
              <>
                <p>{getTranslation('devise.failure.check_inbox')}</p>
                <div className='resend-a'>
                  <a href='#' className=''>
                      Resend verification email
                  </a>
                </div>
              </> : ''
            }
          >
            {getAlertMessage('email', error)}
          </Input>
        {status == 'login' && 
          <>
          <Input 
            type="password" 
            placeholder="Password"
            name="user[password]" 
            required={true} 
            errorMessageStatus={loginErrorMessages.includes(props?.messages?.alert)}
            errorMessage={
              <>
              {props.messages.alert == 'invalid_password' ? 
              <>
                <p>{getTranslation('devise.failure.invalid1')}</p>
                <div className='resend-a'>
                  <a href='#' className=''>
                      Resend verification email
                  </a>
                </div>
              </> : 
              ''}
              
              </>
              } 
            >
              {loginErrorMessages.includes(props?.messages?.alert) && getTranslation('devise.failure.incorrect_credentials') && <span className='has-error'>
                {loginErrorMessages.includes(props?.messages?.alert) && getTranslation('devise.failure.incorrect_credentials')}
              </span>}
            </Input>
            <Input 
              classList='remember-me-wrapper p-0 text-start'
              className="me-1"
              type="checkbox"
              name="user[remember_me]"
              id="rememberMeCheckbox"
            >
              <span className='d-flex justify-content-between align-middle w-100'>
                <label className="" htmlFor="rememberMeCheckbox">Remember Me </label>
                <img src={gdpr_badge} alt="GDPR Badge" height={'25px'} onClick={() => setPolicyModalStatus(true) } style={{cursor:'pointer'}}/>
              </span>
            </Input>
            <Button classList="login-btn" type="submit" text='Log In' name="commit" value='Log In' />
            <div className="signup-action-wrapper">
              <p>Don't have an account? <a href="/signup" className='text-underline'>Sign Up</a></p>
              <p className='text-underline'><a href="/users/password/new">Forgot password?</a></p>
            </div>
            <div className="d-flex terms-wrapper position-relative">
              <p onClick={() => setTermsModalStatus(true)} className='col-4 text-start'>Terms of Service</p>
              <DropDown 
                title={<u>options</u>} 
                classList="col-8"
                customClassList="text-dropdown"
                varient=""
                optionList={
                [
                  {"url":"/users/confirmation/new", "title": "Resend Activation Link"},
                  {"url":"/users/unlock/new", "title": "Unlock Account"},
                  {"url":"/users/password/new", "title": "Forgot password?"},
                ]
              } />
            </div>
          </>
        }
        {
          (status == 'confirmation' || status == 'unlock' || status == 'forgotPassword') &&
          <>
            <Button classList="login-btn" type="submit" text={getTranslation(gettanslationStringOnBehalfOfStatus[status])} name="commit" value={getTranslation(gettanslationStringOnBehalfOfStatus[status])} />
            <a className='text-decoration-none d-block my-2' href="/users/login">Login</a>
            <a className='text-decoration-none d-block my-2' href="/signup">Sign Up</a>
          </>
        }
        {
          status == 'signup' && 
          <>
            <Input 
              type="password" 
              placeholder="Password (6 characters minimum)"
              name="user[password]" 
              required={true} 
              errorMessage={
                <>
                {props.messages.alert == 'invalid_password' ? 
                <>
                  <p>{getTranslation('devise.failure.invalid1')}</p>
                  <div className='resend-a'>
                    <a href='#' className=''>
                        Resend verification email
                    </a>
                  </div>
                </> : 
                ''}
                {loginErrorMessages.includes(props?.messages?.alert) && getTranslation('devise.failure.incorrect_credentials')}
                </>
                } 
            />
            <Button classList="login-btn" type="submit" text="Create My Account »" name="commit" value="Create My Account »" />
            <p className="terms-text">By continuing, you agree to the <a href="javascript:void(0)" onClick={() => setTermsModalStatus(true)}>Terms of Service</a></p>
            <p className="terms-text large">Already have an account? <a href="/users/login" className="text-decoration-underline"> Log In</a></p>
          </>
        }
        </div>
      </form>
      
      <>
      <TermsAndPolicyModal 
        modalStatus={termsModalStatus}
        modalActiveStatus={setTermsModalStatus}
        title="Terms of Use and Service" 
        >
          <TermsAndUseContent />
      </TermsAndPolicyModal>
      <TermsAndPolicyModal 
        modalStatus={policyModalStatus}
        modalActiveStatus={setPolicyModalStatus}
        title="Privacy Notice" 
        >
        <PrivacyNotice />
      </TermsAndPolicyModal>
      </>
    </div>
  );
};

export default Login;

