import React from 'react';
import { pdfjs } from 'react-pdf';
import PdfViewer from './PdfViewer';

pdfjs.GlobalWorkerOptions.workerSrc = '/worker.js';

const Viewer = ({ zoom, url }) => {
    return (
        <>
            <PdfViewer pdfFile={url} zoom={zoom} />
        </>
    );
};

export default Viewer;