import React, { useRef, useState, useEffect } from "react";
import resize_right from '../.././../../../../assets/images/resize_right.svg'

const ResizeableElement = ({ children, width, height }) => {
    const resizableRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width, height });
    const [isResizing, setIsResizing] = useState(false);
    const aspectRatio = width / height; // Calculate initial aspect ratio

    const handleMouseDown = (e) => {
        e.stopPropagation();
        setIsResizing(true);
    };

    const handleMouseMove = (e) => {
        if (!isResizing) return;

        const newWidth = e.clientX - resizableRef.current.getBoundingClientRect().left;

        // Calculate new height based on aspect ratio
        const newHeight = newWidth / aspectRatio;

        setDimensions({
            width: newWidth > 100 ? newWidth : 100, // Minimum width
            height: newHeight > 100 ? newHeight : 100 // Minimum height
        });
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    useEffect(() => {
        if (isResizing) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        } else {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        }

        // Cleanup on unmount
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing]); // Re-run effect when isResizing changes

    useEffect(() => {
        setDimensions({ width, height });
    }, [width, height])

    return (
        <div
            ref={resizableRef}
            className="resizable"
            style={{
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
                position: "relative",
                padding: '1px'
            }}
        >
            {children}
            <div
                className="resize-handle"
                onMouseDown={handleMouseDown}
            >
                <img src={resize_right} alt='resize icon' style={{ width: 20, height: 20 }} />
            </div>
        </div>
    );
};

export default ResizeableElement;
