import React from "react"
const TagEditorSection = () => {
    return (
        <div className="tag-section me-2">
            <input type="text" className="input-tag" placeholder="Please add a new Page Tag here" />
            <div className="toggle-group">
                <label>Include in engagement stats</label>
                <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                </label>
            </div>

            <div className="toggle-group">
                <label>Apply to all language editions</label>
                <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    )
}
export default TagEditorSection