import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from "./Header/Header";
import SideBar from "./Sidebar/SideBar";
import Footer from "./Footer/Footer";
import './mainLayout.css';
import UserInfoContext from '../../utis/userInfoContext';

// const HOCChildComponent = (Component) => {
//     return (props) => <Component {...props} />
// }

const MainLayout = ({header, props, children}) => {
    // const HOCChild = React.memo(HOCChildComponent(children.type));
    const [userData, setUserData] = useState('');
    const [userProfileData, setUserProfileData] = useState('');
    const [userCompanyData, setUserCompanyData] = useState('');
    return(
        <UserInfoContext.Provider value={{userInfoData:userData, profileData:userProfileData, companyData:userCompanyData, setUserData, setUserProfileData, setUserCompanyData}}>
            <div className='main-layout-wrapper'>
                <aside className='side-nav'>
                    <SideBar />
                </aside>
                <main className='main-wrapper'>
                    <Header heading={header} />
                    <div className='main-wrapper-child'>
                        {/* <HOCChild { ...children.props } /> */}
                        {children}
                    </div>
                    <Footer />
                </main>                
            </div>
        </UserInfoContext.Provider>
    )
}
export default MainLayout;